import React from 'react';
import { Card, Form } from 'react-bootstrap';
import HOC from '../shared/HOC';
import Loading from '../shared/Loading/Loading';
import WrapperCard from '../shared/Cards/WrapperCard';

const ChangePassword = (props) => (
  <WrapperCard
    header={{
      exist: true,
      data: { iconName: 'bx bx-key', title: 'Change Password' },
    }}
  >
    <Card.Body>
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <strong>Password Reset</strong>
          </span>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="col-12 px-0">
                <Form.Group controlId="oldPassword">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    placeholder="Old Password"
                    type="password"
                    className={props.data.oldPasswordError ? 'is-invalid' : ''}
                    value={props.data.oldPassword}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                  {props.data.oldPasswordError !== '' && (
                    <Form.Text>{props.data.oldPasswordError}</Form.Text>
                  )}
                </Form.Group>
              </div>
              <div className="col-12 px-0">
                <Form.Group controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    placeholder="New Password"
                    type="password"
                    className={props.data.newPasswordError ? 'is-invalid' : ''}
                    value={props.data.newPassword}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                  {props.data.newPasswordError !== '' && (
                    <Form.Text>{props.data.newPasswordError}</Form.Text>
                  )}
                </Form.Group>
              </div>
              <div className="col-12 px-0">
                <Form.Group controlId="confirmNewPassword">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    placeholder="Confirm New Password"
                    type="password"
                    className={
                      props.data.confirmNewPasswordError ? 'is-invalid' : ''
                    }
                    value={props.data.confirmNewPassword}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                    disabled={props.data.newPassword.trim() === ''}
                  />
                  {props.data.confirmNewPasswordError !== '' && (
                    <Form.Text>{props.data.confirmNewPasswordError}</Form.Text>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
          <button
            type="submit"
            className="btn main-btn icon-btn"
            onClick={() => props.handlers.handleChangePassword()}
            disabled={props.data.passwordLoading}
          >
            {!props.data.passwordLoading ? (
              <HOC>
                <i className="bx bx-save" />
                <span>Save Settings</span>
              </HOC>
            ) : (
              <Loading />
            )}
          </button>
        </div>
      </div>
    </Card.Body>
  </WrapperCard>
);

export default ChangePassword;
