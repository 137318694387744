import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Page404 from '../components/shared/Page404/Page404';
import MyAccount from '../layouts/UserSettings/MyAccount';
import Developer from '../layouts/UserSettings/Developer';

const baseUrl = process.env.PUBLIC_URL;

const Routes = () => (
  <div className="UserSettings">
    <Switch>
      <Redirect
        exact
        from={`${baseUrl}/dashboard/settings`}
        to={`${baseUrl}/dashboard/settings/my-account`}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/settings/my-account`}
        component={MyAccount}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/settings/developer-settings`}
        component={Developer}
      />
      <Route path="*" component={Page404} />
    </Switch>
  </div>
);

export default Routes;
