import React from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const AddUserModal = (props) => (
  <WrapperModal
    show={props.data.showAddUserModal}
    onClose={props.handlers.handleShowAddUserModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Add New User',
            handleCloseModal: props.handlers.handleShowAddUserModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Add User',
            handleCloseModal: props.handlers.handleShowAddUserModal,
            handleActionButton: props.handlers.handleAddUser,
            isLoading: props.data.userLoading,
          }}
        />
      ),
    }}
    centered={true}
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleAddUser}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="userName">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user name"
                  className={props.data.userNameError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.userNameError && (
                  <Form.Text>User name is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="userEmail">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user email"
                  className={props.data.userEmailError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.userEmailError !== '' && (
                  <Form.Text>{props.data.userEmailError}</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter phone number"
                  className={props.data.phoneNumberError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.phoneNumberError && (
                  <Form.Text>Phone number is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Select
                  isSearchable={true}
                  name="companyList"
                  options={props.data.companyList}
                  placeholder="Select Company"
                  onChange={(e) => props.handlers.handleSelectCompany(e)}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: props.data.companyNameError
                        ? '1px solid #dc3545'
                        : '1px solid #ced4da',
                    }),
                  }}
                />
                {props.data.companyNameError && (
                  <Form.Text>Company name is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="apiAccess">
                <Form.Label>For Technical User</Form.Label>
                <Form.Check
                  type="switch"
                  label="API Access"
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default AddUserModal;
