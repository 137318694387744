import React from 'react';
import { Form, Table } from 'react-bootstrap';
import HOC from '../shared/HOC';

const SendSMSDocumentation = () => (
  <HOC>
    <div className="col-12">
      <h4>
        <strong>Method: /sendSMS</strong>
      </h4>
    </div>
    <div className="col-12 col-md-7">
      <Form.Label>Request: POST</Form.Label>
      <Table responsive>
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Type</th>
            <th>Position</th>
            <th>#</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>destination</td>
            <td>
              <code>String</code>
            </td>
            <td>
              <code>Body</code>
            </td>
            <td>
              <code>Mandatory</code>
            </td>
            <td>MSISDN Destination</td>
          </tr>
          <tr>
            <td>body</td>
            <td>
              <code>String</code>
            </td>
            <td>
              <code>Body</code>
            </td>
            <td>
              <code>Mandatory</code>
            </td>
            <td>Message Body</td>
          </tr>
        </tbody>
      </Table>
    </div>
    <div className="col-12 col-md-5">
      <Form.Label>Response: Success</Form.Label>
      <pre>
        {`                  
  {
    "code": 200,
    "message": "Processed Successfully",
    "status": "OK",
    "data": {
        "id": 139,
        "status": "pending",
        "companyName": "company name",
        "shortcode": "68001",
        "destination": "123123123",
        "content": "SMS Body from callback sms",
        "encoding": "GSM",
        "messageCount": 1
    }
  }
      `}
      </pre>
      <Form.Label>Response: Failed</Form.Label>
      <pre>
        {`                  
  {
    "code": 4XX,
    "message": "Error Message",
    "status": false,
    "data": null
  }
      `}
      </pre>
    </div>
  </HOC>
);

export default SendSMSDocumentation;
