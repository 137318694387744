import React from 'react';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseOnlyFooter } from '../shared/Modals/Footers/FooterFunctions';

const VeficiationModal = (props) => (
  <WrapperModal
    show={props.showVerificationModal}
    onClose={props.handleCloseModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: props.verificationTitle,
            handleCloseModal: props.handleCloseModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseOnlyFooter
          data={{
            actionTitle: 'Reset Password',
            handleCloseModal: props.handleCloseModal,
          }}
        />
      ),
    }}
    centered={true}
    styles="ForgotPassword"
  >
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <h6>{props.verificationMessage}</h6>
          </div>
        </div>
      </div>
    </div>
  </WrapperModal>
);

export default VeficiationModal;
