import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import { passwordRegex } from '../../helpers/regex';
import { updateUserProfile } from '../../store/actions/authAction';
import { putRequest } from '../../helpers/apiHandlers';
import config from '../../config/constant';
import EditAccount from '../../components/UserSettings/EditAccount';
import ChangePassword from '../../components/UserSettings/ChangePassword';
import ChangeEmail from '../../components/UserSettings/ChangeEmail';

const utcLists = config.utc;

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: this.props.auth.user.companyId.name
        ? this.props.auth.user.companyId.name
        : '',
      companyCountry: this.props.auth.user.companyId.country
        ? this.props.auth.user.companyId.country
        : '',
      companyFullAddress: this.props.auth.user.companyId.address
        ? this.props.auth.user.companyId.address
        : '',
      userName: this.props.auth.user.username
        ? this.props.auth.user.username
        : '',
      phoneNumber: this.props.auth.user.phone
        ? parseInt(this.props.auth.user.phone)
        : '',
      skypeID: this.props.auth.user.skype ? this.props.auth.user.skype : '',
      position: this.props.auth.user.position
        ? this.props.auth.user.position
        : '',
      utcList: utcLists,
      defaultUTC: {
        label: this.props.auth.user.utc.toString(),
        value: this.props.auth.user.utc.toString(),
      },
      selectedUTC: {},
      encodingAutodetect:
        parseInt(this.props.auth.user.encoding) === 1 ? true : false,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      oldEmail: '',
      newEmail: '',
      confirmNewEmail: '',
      passwordLoading: false,
      emailLoading: false,

      /* Error Handling */
      userNameError: '',
      oldPasswordError: '',
      newPasswordError: '',
      confirmNewPasswordError: '',
      oldEmailError: '',
      newEmailError: '',
      confirmNewEmailError: '',
    };

    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleUpdateMyAccount = this.handleUpdateMyAccount.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSelectUTC = this.handleSelectUTC.bind(this);
  }

  handleUserInput(e, type) {
    const { id, value, checked } = e.target;

    if (!type) {
      this.setState({ [id]: value }, () => {
        this.validateField(id, value);
      });
    } else {
      this.setState({ [id]: checked ? true : false });
    }
  }

  validateField(fieldName, value) {
    switch (fieldName) {
      case 'userName':
        if (this.state.userName.trim() !== '') {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Username is required',
          });
        }
        break;
      case 'oldPassword':
        if (this.state.oldPassword.trim() !== '') {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Password is required',
          });
        }
        break;
      case 'newPassword':
        if (this.state.confirmNewPassword.trim() !== '') {
          this.setState({ confirmNewPassword: '' });
        }

        if (passwordRegex.test(value)) {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']:
              'MUST contain at least 8 characters, 1 uppercase, 1 lowercase & 1 number.',
          });
        }
        break;
      case 'confirmNewPassword':
        if (value === this.state.newPassword) {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Password does not match',
          });
        }
        break;
      case 'oldEmail':
        if (this.state.oldEmail.trim() !== '') {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Email is required',
          });
        }
        break;
      case 'newEmail':
        if (this.state.confirmNewEmail.trim() !== '') {
          this.setState({ confirmNewEmail: '' });
        }

        if (isEmail(value)) {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Invalid email format',
          });
        }
        break;
      case 'confirmNewEmail':
        if (value === this.state.newEmail) {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Email does not match',
          });
        }
        break;
      default:
        break;
    }
  }

  handleUpdateMyAccount() {
    if (this.state.userName.trim() !== '') {
      const data = {
        companyAddress: this.state.companyFullAddress,
        username: this.state.userName,
        phone: this.state.phoneNumber,
        skype: this.state.skypeID,
        position: this.state.position,
        utc: this.state.selectedUTC.value,
        encoding: this.state.encodingAutodetect === true ? 1 : 0,
      };

      this.props.updateUserProfile(data, this.props.auth.token);
    } else {
      this.validateField('userName', this.state.userName);
    }
  }

  handleChangePassword() {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;

    if (
      oldPassword.trim() !== '' &&
      newPassword.trim() !== '' &&
      confirmNewPassword.trim() === newPassword
    ) {
      this.setState({ passwordLoading: true });

      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };

      putRequest('/me/change-password', data, this.props.auth.token, (res) => {
        this.setState({ passwordLoading: false });
        if (res.success) {
          Swal.fire('Updated!', 'Your password have been updated.', 'success');
        } else {
          Swal.fire(
            'Failed!',
            `Your new password was not updated. <br />${res.message}`,
            'error'
          );
        }
      });
    } else {
      this.validateField('oldPassword', oldPassword);
      this.validateField('newPassword', newPassword);
      this.validateField('confirmNewPassword', confirmNewPassword);
    }
  }

  handleChangeEmail() {
    const { oldEmail, newEmail, confirmNewEmail } = this.state;

    if (
      oldEmail.trim() !== '' &&
      newEmail.trim() !== '' &&
      confirmNewEmail.trim() === newEmail
    ) {
      this.setState({ emailLoading: true });

      const data = {
        oldEmail: oldEmail,
        newEmail: newEmail,
      };

      putRequest('/me/change-email', data, this.props.auth.token, (res) => {
        this.setState({ emailLoading: false });
        if (res.success) {
          Swal.fire('Updated!', 'Your email have been updated.', 'success');
        } else {
          Swal.fire(
            'Failed!',
            `Your new email was not updated. <br />${res.message}`,
            'error'
          );
        }
      });
    } else {
      this.validateField('oldEmail', oldEmail);
      this.validateField('newEmail', newEmail);
      this.validateField('confirmNewEmail', confirmNewEmail);
    }
  }

  handleSelectUTC(e) {
    this.setState({ selectedUTC: e });
  }

  render() {
    const {
      handleUserInput,
      handleUpdateMyAccount,
      handleChangePassword,
      handleChangeEmail,
      handleSelectUTC,
    } = this;

    return (
      <div className="EditAccount">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <EditAccount
                  data={this.state}
                  isLoading={this.props.isLoading}
                  handlers={{
                    handleUserInput,
                    handleUpdateMyAccount,
                    handleSelectUTC,
                  }}
                />
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12">
                    <ChangePassword
                      data={this.state}
                      handlers={{ handleUserInput, handleChangePassword }}
                    />
                  </div>
                  <div className="col-12">
                    <ChangeEmail
                      data={this.state}
                      handlers={{ handleUserInput, handleChangeEmail }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const { isLoading } = state.auth;

  return {
    isLoading,
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (res, token) => dispatch(updateUserProfile(res, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
