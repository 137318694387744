import React from 'react';
import TagsInput from 'react-tagsinput';

const AddPhoneNumber = (props) => (
  <div className="AddPhoneNumber">
    <TagsInput
      value={props.data.tags}
      onChange={props.handlers.handleChangeTags}
      inputValue={props.data.tag}
      onChangeInput={props.handlers.handleChangeTagInput}
      onlyUnique={true}
      inputProps={{
        className: 'react-tagsinput-input',
        placeholder: 'Destination number(s)',
      }}
      className={
        'react-tagsinput ' +
        (props.data.tagsSelectedGroupError ? 'invalid' : '')
      }
      addOnBlur={true}
      addKeys={[9, 13, 32]} // An array of key codes that add a tag, Tab, Enter, Space
      maxTags={50} // recipients: array of phone numbers to send the SMSs to ( maximum 50 )
    />
  </div>
);

export default AddPhoneNumber;
