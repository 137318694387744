import axios from 'axios';
import cogoToast from 'cogo-toast';
import { store } from '../store/store';
import {
  setRefreshTokenSuccess,
  logoutUser,
} from '../store/actions/authAction';
import config from '../config/constant';

const API_BASE_URL = config.urls.api_base_url;

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    /* Only goes into if condition if token expires */
    if (
      response.data.data &&
      response.data.code === 401 &&
      response.data.data.error === 'jwt expired'
    ) {
      const authRedux = store.getState().auth;

      const data = {
        refreshToken: authRedux.refreshToken,
        staySignIn: authRedux.staySignIn,
      };

      const headers = {
        headers: {
          Authorization: `Bearer ${authRedux.token}`,
        },
      };

      return axios
        .post(API_BASE_URL + '/refresh-token', data, headers)
        .then((res) => {
          if (res.data.code === 200) {
            store.dispatch(setRefreshTokenSuccess(res.data.data));
            response.config.headers[
              'Authorization'
            ] = `Bearer ${res.data.data.token}`;
            return axios(response.config);
          } else if (
            res.data.code === 401 &&
            res.data.message === 'Session expired'
          ) {
            cogoToast.error('User Session expired, please login again', {
              position: 'top-right',
            });
            store.dispatch(logoutUser());
          } else {
            // console.log(res);
          }
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }

    if (
      response.data.code === 401 &&
      response.data.message === 'Account Suspended'
    ) {
      cogoToast.error(response.data.message, { position: 'top-right' });
      store.dispatch(logoutUser());
    }

    return response;
  },

  function (error) {
    // console.log(error.response);

    return Promise.reject(error);
  }
);
