import React from 'react';
import Form from 'react-bootstrap/Form';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const AddGroupContact = (props) => (
  <WrapperModal
    show={props.data.showAddGroupContactModal}
    onClose={props.handlers.handleShowAddGroupContactModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Add New Group',
            handleCloseModal: props.handlers.handleShowAddGroupContactModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Add Group',
            handleCloseModal: props.handlers.handleShowAddGroupContactModal,
            handleActionButton: props.handlers.handleAddNewGroupContact,
            isLoading: props.data.addGroupLoading,
          }}
        />
      ),
    }}
    centered={true}
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleAddNewGroupContact}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="newGroupName">
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter group name"
                  className={props.data.newGroupNameError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.newGroupNameError && (
                  <Form.Text>Group name is required</Form.Text>
                )}
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default AddGroupContact;
