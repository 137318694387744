import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const AddContactModal = (props) => (
  <WrapperModal
    show={props.data.showAddContactModal}
    onClose={props.handlers.handleShowAddContactModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Add New Contact(s)',
            handleCloseModal: props.handlers.handleShowAddContactModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Add Contact(s)',
            handleCloseModal: props.handlers.handleShowAddContactModal,
            handleActionButton: props.handlers.handleAddNewContact,
            isLoading: props.data.addContactLoading,
          }}
        />
      ),
    }}
    centered={true}
    styles={'AddContactModal'}
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleAddNewContact}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="userName">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user name"
                  className={props.data.userNameError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.userNameError && (
                  <Form.Text>User name is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter phone number"
                  className={props.data.phoneNumberError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.phoneNumberError && (
                  <Form.Text>Phone number is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="groupName">
                <Form.Label>Group Name</Form.Label>
                <Select
                  isSearchable={true}
                  name="groupList"
                  options={props.data.groupNameList}
                  placeholder="Select Group"
                  onChange={(e) => props.handlers.handleSelectGroup(e)}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: props.data.selectedGroupNameError
                        ? '1px solid #dc3545'
                        : '1px solid #ced4da',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: '3',
                    }),
                  }}
                />
                {props.data.selectedGroupNameError && (
                  <Form.Text>Group is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="bulkUploadContact">
                <Form.Label>Bulk Upload Contact</Form.Label>
                <Form.Check
                  type="switch"
                  label="Bulk Upload"
                  checked={props.data.bulkUploadContact}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
              </Form.Group>
            </div>
            <div className="col-12">
              <InputGroup className={props.data.bulkFileError ? '' : 'mb-3'}>
                <Form.Group className="custom-file">
                  <span
                    className={
                      'custom-file-label ' +
                      (props.data.bulkFileError ? 'invalid' : '')
                    }
                    htmlFor="bulkFile"
                  >
                    {props.data.bulkFile !== undefined
                      ? props.data.bulkFile.name
                      : 'Choose File (.csv or .txt)'}
                  </span>
                  <Form.File
                    id="bulkFile"
                    aria-describedby="bulkFile"
                    type="file"
                    className="custom-file-input"
                    onChange={(e) => props.handlers.handleUserInput(e)}
                    onClick={() => props.handlers.handleSelectFile()}
                  />
                </Form.Group>
              </InputGroup>
              <Form.Group>
                {props.data.bulkFileError && (
                  <Form.Text>File is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="file-format">
                <Form.Label>Important</Form.Label>
                <Form.Text as="span">
                  File format:
                  <br />
                  <strong>
                    <i>Name;phone number</i>
                  </strong>
                  <br />
                  Every field must be separated by semicolon ( ; ) <br />
                  Example:
                  <br />
                  <strong>
                    <i>
                      Contact Name1;6012345678
                      <br />
                      customer 2;60119998888
                      <br />
                      buyer 01;60887766555
                      <br />
                    </i>
                  </strong>
                </Form.Text>
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default AddContactModal;
