import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Page404 from '../components/shared/Page404/Page404';
import ContactManagements from '../layouts/WebMessaging/ContactManagements';
import SendMessage from '../layouts/WebMessaging/SendMessage';

const baseUrl = process.env.PUBLIC_URL;

const Routes = () => (
  <div className="WebMessaging">
    <Switch>
      <Redirect
        exact
        from={`${baseUrl}/dashboard/web-messaging`}
        to={`${baseUrl}/dashboard/web-messaging/contact-management`}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/web-messaging/contact-management`}
        component={ContactManagements}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/web-messaging/send-message`}
        component={SendMessage}
      />
      <Route path="*" component={Page404} />
    </Switch>
  </div>
);

export default Routes;
