import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const TopupBalanceModal = (props) => (
  <WrapperModal
    show={props.data.showTopupBalanceModal}
    onClose={props.handlers.handleShowTopupBalanceModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Add User Balance',
            handleCloseModal: props.handlers.handleShowTopupBalanceModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Add Balance',
            handleCloseModal: props.handlers.handleShowTopupBalanceModal,
            handleActionButton: props.handlers.handleTopupBalance,
            isLoading: props.data.topupLoading,
          }}
        />
      ),
    }}
    centered={true}
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleTopupBalance}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" type="prepaid-only">
                      Only Companies with PREPAID payment will be shown.
                    </Tooltip>
                  }
                >
                  <i className="bx bx-help-circle ml-1 text-danger" />
                </OverlayTrigger>
                <Select
                  isSearchable={true}
                  name="companyList"
                  options={props.data.prepaidCompanyList}
                  placeholder="Select Company"
                  onChange={(e) =>
                    props.handlers.handleSelectCompany(e, 'topup')
                  }
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: props.data.companyNameError
                        ? '1px solid #dc3545'
                        : '1px solid #ced4da',
                    }),
                  }}
                />
                {props.data.companyNameError && (
                  <Form.Text>Company name is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="currentBalance">
                <Form.Label>Current Balance</Form.Label>
                <p>
                  RM
                  {props.data.currentBalance !== null
                    ? props.data.currentBalance.toLocaleString()
                    : 0}
                </p>
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="addBalanceAmount">
                <Form.Label>Add Balance Amount (RM)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter amount"
                  className={
                    props.data.addBalanceAmountError ? 'is-invalid' : ''
                  }
                  onChange={(e) => props.handlers.handleUserInput(e)}
                  disabled={props.data.currentBalanceError}
                />
                {props.data.addBalanceAmountError && (
                  <Form.Text>Amount is required</Form.Text>
                )}
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default TopupBalanceModal;
