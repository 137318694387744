import React from 'react';
import Card from 'react-bootstrap/Card';
import WrapperCard from '../shared/Cards/WrapperCard';
import Loading from '../shared/Loading/Loading';

const AdminReportSummary = ({ data }) => (
  <WrapperCard
    header={{
      exist: true,
      data: {
        iconName: 'bx bx-clipboard',
        title: 'Report Summary',
      },
    }}
  >
    <Card.Body>
      {data.isSummaryLoading && (
        <div className="loading-white-bg">
          <Loading />
        </div>
      )}
      {!data.isSummaryLoading && Object.keys(data.summaryData).length !== 0 && (
        <div className="col-12">
          <Card.Body>
            <div className="summary-data">
              <div className="row">
                <div className="col-4">
                  <div className="row">
                    <div className="col-6">
                      <strong>Total Messages</strong>
                    </div>
                    <div className="col-6">
                      <strong className="float-right">{`${data.summaryData.message.total.toLocaleString()} Messages`}</strong>
                    </div>
                  </div>
                  {data.summaryData.message.success > 0 && (
                    <div className="row">
                      <div className="col-6 px-5">
                        <strong>Success</strong>
                      </div>
                      <div className="col-6">
                        <strong className="float-right">{`${data.summaryData.message.success.toLocaleString()} Messages`}</strong>
                      </div>
                    </div>
                  )}
                  {data.summaryData.message.pending > 0 && (
                    <div className="row">
                      <div className="col-6 px-5">
                        <strong>Pending</strong>
                      </div>
                      <div className="col-6">
                        <strong className="float-right">{`${data.summaryData.message.pending.toLocaleString()} Messages`}</strong>
                      </div>
                    </div>
                  )}
                  {data.summaryData.message.failed > 0 && (
                    <div className="row">
                      <div className="col-6 px-5">
                        <strong>Failed</strong>
                      </div>
                      <div className="col-6">
                        <strong className="float-right">{`${data.summaryData.message.failed.toLocaleString()} Messages`}</strong>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <strong>Shortcode Breakdown:</strong>
                    </div>
                  </div>
                  {data.summaryData.shortcode.map((sc) => (
                    <div className="row" key={sc.shortcode}>
                      <div className="col-6 pl-5">
                        <strong>{sc.shortcode}</strong>
                      </div>
                      <div className="col-6">
                        <strong className="float-right">
                          {`${sc.total.toLocaleString()} Messages`}
                        </strong>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-12">
                      <strong>Partners Breakdown:</strong>
                    </div>
                  </div>
                  {data.summaryData.partner.map((p) => (
                    <div className="row" key={p.company_id}>
                      <div className="col-6 pl-4">
                        <strong>{p.name}</strong>
                      </div>
                      <div className="col-6">
                        <strong className="float-right">
                          {`${p.totalMessage.toLocaleString()} Messages`}
                        </strong>
                      </div>
                      <div className="col-6 pl-5">
                        <strong>Revenue</strong>
                      </div>
                      <div className="col-6">
                        <strong className="float-right">
                          {`${p.revenue.toLocaleString()} MYR`}
                        </strong>
                      </div>
                    </div>
                  ))}

                  <div className="row">
                    <div className="col-6">
                      <strong>Total Gross Revenue</strong>
                    </div>
                    <div className="col-6">
                      <strong className="float-right">{`${data.summaryData.message.totalCost.toLocaleString()} MYR`}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </div>
      )}
    </Card.Body>
  </WrapperCard>
);

export default AdminReportSummary;
