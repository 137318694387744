import React, { Component } from 'react';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2';
import moment from 'moment';
import Select from 'react-select';
import isEmail from 'validator/lib/isEmail';
import { Card, Form } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { historyPush } from '../../routes/historyPush';
import { getRequest, postRequest, putRequest } from '../../helpers/apiHandlers';
import { domainRegex } from '../../helpers/regex';
import { getUserCredits } from '../../store/actions/authAction';
import Loading from '../../components/shared/Loading/Loading';
import WrapperCard from '../../components/shared/Cards/WrapperCard';
import AddUserModal from '../../components/AdminTools/AddUserModal';
import AddCompanyModal from '../../components/AdminTools/AddCompanyModal';
import TopupBalanceModal from '../../components/AdminTools/TopupBalanceModal';
import BlacklistModal from '../../components/AdminTools/BlacklistModal';
import UserDetailsModal from '../../components/AdminTools/UserDetailsModal';
import UserManagementTable from '../../components/AdminTools/UserManagementTable';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddUserModal: false,
      showAddCompanyModal: false,
      showTopupBalanceModal: false,
      showBlacklistModal: false,
      showUserDetailsModal: false,
      userName: '',
      userEmail: '',
      phoneNumber: '',
      companyName: '',
      apiAccess: false,
      companyEmailDomain: '',
      companyCountry: '',
      companyFullAddress: '',
      paymentType: true,
      addBalanceAmount: 0,
      customerMSISDN: '',
      operationType: true,
      userEmailFilter: '',
      companyNameFilter: '',
      startDate: null,
      endDate: null,
      dateValue: '',
      selectedStatus: [],
      userList: [],
      selectedUser: {},
      filterStatus: [
        { label: 'Active', value: 'active' },
        { label: 'Suspended', value: 'suspended' },
        { label: 'Admin', value: 'admin' },
      ],
      companyList: [],
      prepaidCompanyList: [],
      selectedCompany: {},
      companyLoading: false,
      userLoading: false,
      topupLoading: false,
      blacklistLoading: false,
      showDataLoading: false,
      userDetailsLoading: false,
      userStatusLoading: false,
      currentBalance: null,
      currentPage: 1,
      perPage: 10,
      totalData: 0,

      /* Error Handling */
      userNameError: false,
      userEmailError: '',
      phoneNumberError: false,
      companyNameError: false,
      companyEmailDomainError: false,
      companyCountryError: false,
      companyFullAddressError: false,
      paymentTypeError: false,
      addBalanceAmountError: false,
      customerMSISDNError: false,
      operationTypeError: false,
      currentBalanceError: false,
    };
    this.checkIsAdmin = this.checkIsAdmin.bind(this);
    this.companyDataModeling = this.companyDataModeling.bind(this);
    this.getCompanyList = this.getCompanyList.bind(this);
    this.handleShowData = this.handleShowData.bind(this);
    this.handleShowUserDetails = this.handleShowUserDetails.bind(this);
    this.handleUpdateUserStatus = this.handleUpdateUserStatus.bind(this);
    this.handleMakeAdmin = this.handleMakeAdmin.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleAddCompany = this.handleAddCompany.bind(this);
    this.handleTopupBalance = this.handleTopupBalance.bind(this);
    this.handleBlacklist = this.handleBlacklist.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleShowAddUserModal = this.handleShowAddUserModal.bind(this);
    this.handleShowAddCompanyModal = this.handleShowAddCompanyModal.bind(this);
    this.handleShowTopupBalanceModal =
      this.handleShowTopupBalanceModal.bind(this);
    this.handleShowBlacklistModal = this.handleShowBlacklistModal.bind(this);
    this.handleShowUserDetailsModal =
      this.handleShowUserDetailsModal.bind(this);
    this.resetStates = this.resetStates.bind(this);
    this.handleSelectCompany = this.handleSelectCompany.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.checkIsAdmin();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      this.checkIsAdmin();
    }
  }

  checkIsAdmin() {
    if (this.props.auth.user.status === 'admin') {
      this.getCompanyList();
    } else {
      historyPush('/dashboard');
    }
  }

  companyDataModeling(companyData) {
    if (companyData.length > 0) {
      let tempData = [];

      companyData.map((item) =>
        tempData.push({
          ...item,
          label: item.name,
          value: item.id,
        })
      );

      return tempData;
    }
  }

  prepaidCompanyDataModeling(companyData) {
    if (companyData.length > 0) {
      let tempPrepaidData = [];

      companyData.map((item) => {
        if (item.postpaid === 0) {
          tempPrepaidData.push({
            ...item,
            label: item.name,
            value: item.id,
          });
        }

        return tempPrepaidData;
      });
      return tempPrepaidData;
    }
  }

  getCompanyList() {
    this.setState({ showDataLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/admin/companies', data, (res) => {
      if (res.success) {
        const companyList = this.companyDataModeling(res.data);
        const prepaidCompanyList = this.prepaidCompanyDataModeling(res.data);
        this.setState(
          {
            companyList: companyList,
            prepaidCompanyList: prepaidCompanyList,
          },
          this.handleShowData()
        );
      } else {
        // console.log(res.message);
      }
    });
  }

  handleShowData(showData) {
    if (!this.state.showDataLoading) {
      this.setState({ showDataLoading: true });
    }

    const statusArray = [];
    let statusString = '';
    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        page: showData ? 1 : this.state.currentPage,
        perPage: this.state.perPage,
      },
    };

    if (this.state.startDate !== null && this.state.endDate !== null) {
      data.params.startDate = moment(this.state.startDate).toISOString();
      data.params.endDate = moment(this.state.endDate).toISOString();
    }

    if (this.state.selectedStatus.length > 0) {
      this.state.selectedStatus.map((status) =>
        statusArray.push(status.value.toString())
      );
    }

    statusString = statusArray.join(',');

    if (statusString.trim() !== '') {
      data.params.status = statusString;
    }

    if (this.state.userEmailFilter.trim() !== '') {
      data.params.email = this.state.userEmailFilter;
    }

    if (this.state.companyNameFilter.trim() !== '') {
      data.params.company = this.state.companyNameFilter;
    }

    getRequest('/admin/users', data, (res) => {
      if (res.success) {
        this.setState({
          userList: res.data.users,
          currentPage: res.data.pagination.currentPage,
          perPage: res.data.pagination.perPage,
          totalData: res.data.pagination.totalData,
          showDataLoading: false,
        });
      } else {
        // console.log(res.message);
        this.setState({ showDataLoading: false });
      }
    });
  }

  handleShowUserDetails(id) {
    this.setState({ userDetailsLoading: true });
    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest(`/admin/user/${id}`, data, (res) => {
      if (res.success) {
        this.setState({
          selectedUser: res.data,
          userDetailsLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ userDetailsLoading: false });
      }
    });
  }

  handleUpdateUserStatus(id, status) {
    if (id === this.props.auth.user.id) {
      cogoToast.error('Cannot update own account status', {
        position: 'top-right',
      });
    } else {
      this.setState({ userStatusLoading: true });
      const data = {
        status: status === 'suspended' ? 'active' : 'suspended',
      };

      putRequest(`/admin/user/${id}`, data, this.props.auth.token, (res) => {
        this.setState({ userStatusLoading: false });
        if (res.success) {
          Swal.fire('Success!', 'User status was updated.', 'success');
          this.handleShowData();
        } else {
          Swal.fire(
            'Failed!',
            `User status was not updated. <br /> ${res.message}`,
            'error'
          );
        }
      });
    }
  }

  handleMakeAdmin(id, status) {
    if (id === this.props.auth.user.id) {
      cogoToast.error('Cannot update own account status', {
        position: 'top-right',
      });
    } else {
      this.setState({ userStatusLoading: true });
      const data = {
        status: status === 'active' ? 'admin' : 'active',
      };

      putRequest(`/admin/user/${id}`, data, this.props.auth.token, (res) => {
        this.setState({ userStatusLoading: false });
        if (res.success) {
          Swal.fire('Success!', 'User status was updated.', 'success');
          this.handleShowData();
        } else {
          Swal.fire(
            'Failed!',
            `User status was not updated. <br /> ${res.message}`,
            'error'
          );
        }
      });
    }
  }

  checkVal(fieldName, value) {
    if (fieldName === 'userEmail') {
      if (value.trim() === '') {
        this.setState({
          [fieldName + 'Error']: 'Email is required',
        });
      } else {
        this.validateField(fieldName, value);
      }
    } else if (fieldName === 'companyEmailDomain') {
      if (value.trim() === '') {
        this.setState({
          [fieldName + 'Error']: 'Email domain is required',
        });
      } else {
        this.validateField(fieldName, value);
      }
    } else {
      if (value.trim() === '') {
        this.setState({
          [fieldName + 'Error']: true,
        });
      }
    }
  }

  handleAddUser() {
    const {
      userName,
      userEmail,
      userEmailError,
      phoneNumber,
      companyName,
      apiAccess,
    } = this.state;

    if (
      userName.trim() !== '' &&
      userEmailError.trim() === '' &&
      phoneNumber.trim() !== '' &&
      companyName.toString().trim() !== ''
    ) {
      this.setState({ userLoading: true });

      const data = {
        username: userName,
        email: userEmail,
        phone: phoneNumber,
        companyId: companyName,
        technical: apiAccess ? 1 : 0,
      };

      postRequest('/admin/user', data, this.props.auth.token, (res) => {
        this.setState({ userLoading: false });
        if (res.success) {
          Swal.fire(
            'Success!',
            'We have sent an email with confirmation link to the email address.',
            'success'
          );
          this.handleShowData(true);
        } else {
          Swal.fire(
            'Failed!',
            `User was not added. <br /> ${res.message}`,
            'error'
          );
        }
        if (res.code !== 403) {
          this.handleShowAddUserModal();
        }
      });
    } else {
      this.checkVal('userName', userName);
      this.checkVal('userEmail', userEmail);
      this.checkVal('phoneNumber', phoneNumber);
      this.checkVal('companyName', companyName.toString());
    }
  }

  handleAddCompany() {
    const {
      companyName,
      companyEmailDomain,
      companyEmailDomainError,
      phoneNumber,
      companyCountry,
      companyFullAddress,
      paymentType,
    } = this.state;

    if (
      companyName.trim() !== '' &&
      companyEmailDomainError.trim() === '' &&
      phoneNumber.trim() !== '' &&
      companyCountry.trim() !== '' &&
      companyFullAddress.trim() !== ''
    ) {
      this.setState({ companyLoading: true });

      const data = {
        name: companyName,
        emailDomain: companyEmailDomain,
        phone: phoneNumber,
        country: companyCountry,
        address: companyFullAddress,
        postpaid: paymentType ? 1 : 0,
      };

      postRequest('/admin/company', data, this.props.auth.token, (res) => {
        this.setState({ companyLoading: false });
        if (res.success) {
          Swal.fire('Success!', 'Company have been added.', 'success');
          this.getCompanyList();
          this.handleShowAddCompanyModal();
        } else {
          Swal.fire(
            'Failed!',
            `Company was not added. <br />${res.message}`,
            'error'
          );
          if (
            res.code !== 400 &&
            res.message !== 'Company name already exist'
          ) {
            this.handleShowAddCompanyModal();
          }
        }
      });
    } else {
      this.checkVal('companyName', companyName);
      this.checkVal('companyEmailDomain', companyEmailDomain);
      this.checkVal('phoneNumber', phoneNumber);
      this.checkVal('companyCountry', companyCountry);
      this.checkVal('companyFullAddress', companyFullAddress);
    }
  }

  handleTopupBalance() {
    const { companyName, addBalanceAmount, selectedCompany } = this.state;

    if (companyName.toString().trim() !== '' && addBalanceAmount > 0) {
      this.setState({ topupLoading: true });

      const companyId = companyName;
      const data = {
        amount: parseFloat(addBalanceAmount),
      };

      postRequest(
        `/admin/company/${companyId}/balance`,
        data,
        this.props.auth.token,
        (res) => {
          this.setState({ topupLoading: false });
          if (res.success) {
            Swal.fire(
              'Success!',
              `${selectedCompany.label}'s balance have been updated.`,
              'success'
            );
            this.handleShowData(true);
            if (companyId === this.props.auth.user.companyId.id) {
              this.props.getUserCredits(this.props.auth.token);
            }
          } else {
            Swal.fire(
              'Failed!',
              `${selectedCompany.label}'s balance was not updated. <br /> ${res.message}`,
              'error'
            );
          }
          this.handleShowTopupBalanceModal();
        }
      );
    } else {
      this.checkVal('companyName', companyName.toString());
      this.checkVal(
        'addBalanceAmount',
        addBalanceAmount < 1 ? '' : addBalanceAmount
      );
    }
  }

  handleBlacklist() {
    const { companyName, customerMSISDN, operationType } = this.state;

    if (companyName.toString().trim() !== '' && customerMSISDN.trim() !== '') {
      this.setState({ blacklistLoading: true });

      const data = {
        companyId: companyName,
        phone: customerMSISDN,
        type: operationType ? 'blacklist' : 'whitelist',
      };

      postRequest(`/admin/blacklist`, data, this.props.auth.token, (res) => {
        this.setState({ blacklistLoading: false });
        if (res.success) {
          Swal.fire('Success!', 'Blacklist have been updated.', 'success');
        } else {
          Swal.fire(
            'Failed!',
            `Blacklist was not updated. <br /> ${res.message}`,
            'error'
          );
        }
        this.handleShowBlacklistModal();
      });
    } else {
      this.checkVal('companyName', companyName.toString());
      this.checkVal('customerMSISDN', customerMSISDN);
    }
  }

  validateField(fieldName, value) {
    switch (fieldName) {
      case 'userEmail':
        if (isEmail(value)) {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Invalid email format',
          });
        }
        break;
      case 'companyEmailDomain':
        if (domainRegex.test(value)) {
          this.setState({
            [fieldName + 'Error']: '',
          });
        } else {
          this.setState({
            [fieldName + 'Error']: 'Invalid email domain format',
          });
        }
        break;
      default:
        break;
    }
  }

  handleUserInput(e) {
    const id = e.target.id;
    const { value, checked } = e.target;

    if (id === 'userName') {
      this.setState({ userName: value });

      if (this.state.userNameError) {
        this.setState({ userNameError: false });
      }
    } else if (id === 'userEmail') {
      this.setState({ userEmail: value }, () => this.validateField(id, value));

      // if (this.state.userEmailError) {
      //   this.setState({ userEmailError: false });
      // }
    } else if (id === 'phoneNumber') {
      this.setState({ phoneNumber: value });

      if (this.state.phoneNumberError) {
        this.setState({ phoneNumberError: false });
      }
    } else if (id === 'companyName') {
      this.setState({ companyName: value });

      if (this.state.companyNameError) {
        this.setState({ companyNameError: false });
      }
    } else if (id === 'apiAccess') {
      this.setState({ apiAccess: checked });
    } else if (id === 'companyEmailDomain') {
      this.setState({ companyEmailDomain: value }, () =>
        this.validateField(id, value)
      );

      if (this.state.companyEmailDomainError) {
        this.setState({ companyEmailDomainError: false });
      }
    } else if (id === 'companyCountry') {
      this.setState({ companyCountry: value });

      if (this.state.companyCountryError) {
        this.setState({ companyCountryError: false });
      }
    } else if (id === 'companyFullAddress') {
      this.setState({ companyFullAddress: value });

      if (this.state.companyFullAddressError) {
        this.setState({ companyFullAddressError: false });
      }
    } else if (id === 'paymentType1') {
      this.setState({ paymentType: true });
    } else if (id === 'paymentType2') {
      this.setState({ paymentType: false });
    } else if (id === 'addBalanceAmount') {
      this.setState({ addBalanceAmount: value });

      if (this.state.addBalanceAmountError) {
        this.setState({ addBalanceAmountError: false });
      }
    } else if (id === 'customerMSISDN') {
      this.setState({ customerMSISDN: value });

      if (this.state.customerMSISDNError) {
        this.setState({ customerMSISDNError: false });
      }
    } else if (id === 'operationType1') {
      this.setState({ operationType: true });
    } else if (id === 'operationType2') {
      this.setState({ operationType: false });
    } else if (id === 'userEmailFilter') {
      this.setState({ userEmailFilter: value });
    } else if (id === 'companyNameFilter') {
      this.setState({ companyNameFilter: value });
    }
  }

  handleShowAddUserModal() {
    this.setState(
      {
        showAddUserModal: !this.state.showAddUserModal,
      },
      () => {
        if (!this.state.showAddUserModal) {
          this.resetStates();
        }
      }
    );
  }

  handleShowAddCompanyModal() {
    this.setState(
      {
        showAddCompanyModal: !this.state.showAddCompanyModal,
      },
      () => {
        if (!this.state.showAddCompanyModal) {
          this.resetStates();
        }
      }
    );
  }

  handleShowTopupBalanceModal() {
    this.setState(
      {
        showTopupBalanceModal: !this.state.showTopupBalanceModal,
        currentBalance: null,
      },
      () => {
        if (!this.state.showTopupBalanceModal) {
          this.resetStates();
        }
      }
    );
  }

  handleShowBlacklistModal() {
    this.setState(
      {
        showBlacklistModal: !this.state.showBlacklistModal,
      },
      () => {
        if (!this.state.showBlacklistModal) {
          this.resetStates();
        }
      }
    );
  }

  handleShowUserDetailsModal(id) {
    this.setState(
      {
        showUserDetailsModal: !this.state.showUserDetailsModal,
      },
      () => {
        if (this.state.showUserDetailsModal) {
          this.handleShowUserDetails(id);
        }
      }
    );
  }

  resetStates() {
    this.setState({
      userName: '',
      userEmail: '',
      phoneNumber: '',
      companyName: '',
      apiAccess: false,
      companyEmailDomain: '',
      companyCountry: '',
      companyFullAddress: '',
      paymentType: true,
      addBalanceAmount: 0,
      customerMSISDN: '',
      operationType: true,
      userNameError: false,
      userEmailError: '',
      phoneNumberError: false,
      companyNameError: false,
      companyEmailDomainError: false,
      companyCountryError: false,
      companyFullAddressError: false,
      paymentTypeError: false,
      addBalanceAmountError: false,
      customerMSISDNError: false,
      operationTypeError: false,
    });
  }

  handleSelectCompany(e, type) {
    this.setState(
      {
        companyName: e.value,
        selectedCompany: e,
      },
      () => {
        if (this.state.companyNameError) {
          this.setState({
            companyNameError: false,
          });
        }

        if (type === 'topup') {
          const data = {
            headers: {
              Authorization: `Bearer ${this.props.auth.token}`,
            },
          };
          getRequest(`/admin/company/${e.value}/balance`, data, (res) => {
            if (res.success) {
              this.setState({
                currentBalance: res.data.balance,
                currentBalanceError: false,
              });
            } else {
              cogoToast.error(res.message, { position: 'top-right' });
              this.setState({
                currentBalance: 0,
                currentBalanceError: true,
              });
            }
          });
        }
      }
    );
  }

  handleDatePicker(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        dateValue: dateValue,
        startDate: p.startDate,
        endDate: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        dateValue: dateValue,
        startDate: null,
        endDate: null,
      });
    }
  }

  handleSelectStatus(e) {
    this.setState({ selectedStatus: e });
  }

  handlePageChange(pageNo) {
    this.setState(
      {
        currentPage: pageNo,
      },
      () => {
        this.handleShowData();
      }
    );
  }

  render() {
    const {
      handleUserInput,
      handleShowAddUserModal,
      handleShowAddCompanyModal,
      handleSelectCompany,
      handleShowTopupBalanceModal,
      handleShowBlacklistModal,
      handleAddUser,
      handleAddCompany,
      handleTopupBalance,
      handleBlacklist,
      handleShowUserDetailsModal,
      handleUpdateUserStatus,
      handleMakeAdmin,
      handlePageChange,
    } = this;

    return (
      <div className="UserManagement">
        <div className="row">
          <div className="col-12">
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-group',
                  title: 'ADMIN - User Management',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12">
                    <div className="user-management-buttons">
                      <button
                        type="button"
                        className="btn main-btn icon-btn"
                        onClick={() => handleShowAddUserModal()}
                      >
                        <i className="bx bx-user-plus" />
                        <span>Add User</span>
                      </button>
                      <button
                        type="button"
                        className="btn main-btn icon-btn"
                        onClick={() => handleShowAddCompanyModal()}
                      >
                        <i className="bx bx-building-house" />
                        <span>Add Company</span>
                      </button>
                      <button
                        type="button"
                        className="btn main-btn icon-btn"
                        onClick={() => handleShowTopupBalanceModal()}
                      >
                        <i className="bx bx-dollar" />
                        <span>Top up Balance</span>
                      </button>
                      <button
                        type="button"
                        className="btn main-btn icon-btn"
                        onClick={() => handleShowBlacklistModal()}
                      >
                        <i className="bx bx-block" />
                        <span>Blacklist</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Body className="py-0">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="dateRange">
                      <Form.Label>Registered Date Range</Form.Label>
                      <DateRangePicker
                        initialSettings={{
                          locale: {
                            cancelLabel: 'Clear',
                            format: 'DD/MM/YYYY hh:mm A',
                          },
                          autoUpdateInput: false,
                          timePicker: true,
                        }}
                        onEvent={(event, picker) =>
                          this.handleDatePicker(event, picker)
                        }
                      >
                        <Form.Control
                          type="text"
                          className="form-control datetime"
                          placeholder="Select Date"
                          aria-invalid="false"
                          autoComplete="off"
                        />
                      </DateRangePicker>
                    </Form.Group>
                  </div>
                </div>
              </Card.Body>
              <Card.Body className="pt-0">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="userStatus">
                      <Form.Label>Filter By</Form.Label>
                      <Select
                        isMulti
                        name="filterBy"
                        placeholder="Select User Status"
                        options={this.state.filterStatus}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#2f2f2f',
                            color: '#fff',
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: '#fff',
                          }),
                        }}
                        onChange={(e) => this.handleSelectStatus(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="user-email">
                      <Form.Group controlId="userEmailFilter">
                        <Form.Control
                          placeholder="User Email"
                          type="text"
                          onChange={(e) => this.handleUserInput(e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="companyNameFilter">
                      <Form.Control
                        placeholder="Company Name"
                        type="text"
                        onChange={(e) => this.handleUserInput(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
                    <button
                      type="submit"
                      className="btn main-btn"
                      onClick={() => this.handleShowData(true)}
                      disabled={this.state.showDataLoading}
                    >
                      {this.state.showDataLoading ? (
                        <Loading />
                      ) : (
                        <span>Show Data</span>
                      )}
                    </button>
                  </div>
                  <UserManagementTable
                    data={this.state}
                    handleShowUserDetailsModal={handleShowUserDetailsModal}
                    handleUpdateUserStatus={handleUpdateUserStatus}
                    handleMakeAdmin={handleMakeAdmin}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </Card.Body>
            </WrapperCard>
          </div>
        </div>
        <AddUserModal
          data={this.state}
          handlers={{
            handleUserInput,
            handleShowAddUserModal,
            handleAddUser,
            handleSelectCompany,
          }}
        />
        <AddCompanyModal
          data={this.state}
          handlers={{
            handleUserInput,
            handleShowAddCompanyModal,
            handleAddCompany,
          }}
        />
        <TopupBalanceModal
          data={this.state}
          handlers={{
            handleUserInput,
            handleShowTopupBalanceModal,
            handleTopupBalance,
            handleSelectCompany,
          }}
        />
        <BlacklistModal
          data={this.state}
          handlers={{
            handleUserInput,
            handleShowBlacklistModal,
            handleBlacklist,
            handleSelectCompany,
          }}
        />
        {Object.keys(this.state.selectedUser).length !== 0 && (
          <UserDetailsModal
            data={this.state}
            handlers={{
              handleShowUserDetailsModal,
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getUserCredits: (token) => dispatch(getUserCredits(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
