import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { Card, Form, Table } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Loading from '../shared/Loading/Loading';
import WrapperCard from '../shared/Cards/WrapperCard';

const ContactManagement = (props) => (
  <WrapperCard
    header={{
      exist: true,
      data: { iconName: 'bx bx-user', title: 'Contact Management' },
    }}
  >
    <Card.Body>
      <div className="row">
        <div className="col-12 col-md-6">
          <Form.Group controlId="dateRange1">
            <Form.Label>Created Date Range</Form.Label>
            <DateRangePicker
              initialSettings={{
                timePicker: true,
                locale: {
                  cancelLabel: 'Clear',
                  format: 'DD/MM/YYYY hh:mm A',
                },
                autoUpdateInput: false,
              }}
              onEvent={(event, picker) =>
                props.handlers.handleDatePickerContact(event, picker)
              }
            >
              <Form.Control
                type="text"
                className="form-control datetime"
                placeholder="Select Date"
                aria-invalid="false"
                autoComplete="off"
              />
            </DateRangePicker>
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <div className="contact-management-buttons">
            <button
              type="button"
              className="btn main-btn icon-btn"
              onClick={() => props.handlers.handleShowAddContactModal()}
            >
              <i className="bx bx-user-plus" />
              Add Contact
            </button>
          </div>
        </div>
      </div>
    </Card.Body>
    <Card.Body className="pt-0">
      <div className="row">
        <div className="col-12 col-md-6">
          <Form.Group controlId="groupFilter">
            <Form.Label>Filter By</Form.Label>
            <Select
              isMulti
              name="filterBy"
              placeholder="Select Group"
              options={props.data.groupListFilter}
              className="basic-multi-select"
              classNamePrefix="select"
              styles={{
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#2f2f2f',
                  color: '#fff',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: '#fff',
                }),
              }}
              onChange={(e) => props.handlers.handleGroupFilterContact(e)}
            />
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <div className="contact-name">
            <Form.Group controlId="contactName">
              <Form.Control
                placeholder="Contact Name"
                type="text"
                onChange={(e) => props.handlers.handleUserInput(e)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="contactNumber">
            <Form.Control
              placeholder="Contact Number"
              type="number"
              onChange={(e) => props.handlers.handleUserInput(e)}
            />
          </Form.Group>
        </div>
        <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
          <button
            type="submit"
            className="btn main-btn"
            onClick={() => props.handlers.handleShowDataContacts(true)}
            disabled={props.data.showContactLoading}
          >
            {props.data.showContactLoading ? (
              <Loading />
            ) : (
              <span>Show Data</span>
            )}
          </button>
        </div>
        <div className="col-12 mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th>#ID</th>
                <th>Contact Name</th>
                <th>Contact Number</th>
                <th>Group Name</th>
                <th>Created Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {props.data.showContactLoading && (
                <tr>
                  <td colSpan="6" className="loading-white-bg">
                    <Loading />
                  </td>
                </tr>
              )}
              {!props.data.showContactLoading &&
                props.data.contactList.length > 0 &&
                props.data.contactList.map((contact) => (
                  <tr key={contact.id}>
                    <td>{contact.id}</td>
                    <td>{contact.name}</td>
                    <td>{contact.phone}</td>
                    <td>{contact.groupId.name}</td>
                    <td>
                      {moment(contact.createdAt).format(
                        'DD MMMM YYYY, h:mm:ss a'
                      )}
                    </td>
                    <td>
                      <i
                        className="bx bx-trash"
                        onClick={() =>
                          props.handlers.handleDeleteContact(contact.id)
                        }
                      />
                    </td>
                  </tr>
                ))}
              {!props.data.showContactLoading &&
                props.data.contactList.length === 0 && (
                  <tr className="text-center">
                    <td colSpan="6">
                      <img
                        alt="no-data-found"
                        src={require('../../assets/shared/search.svg').default}
                      />
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
          {props.data.contactPagination.totalData > 10 && (
            <div className="pagination-custom-style">
              <Pagination
                activePage={props.data.contactPagination.currentPage}
                itemsCountPerPage={props.data.contactPagination.perPage}
                totalItemsCount={props.data.contactPagination.totalData}
                onChange={(e) => props.handlers.handleContactPageChange(e)}
                hideFirstLastPages
                prevPageText="<PREV"
                nextPageText="NEXT>"
                activeClass="active-page"
                activeLinkClass="active-link"
                itemClass=""
                linkClass="defaul-link"
              />
            </div>
          )}
        </div>
      </div>
    </Card.Body>
  </WrapperCard>
);

export default ContactManagement;
