import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import FileSaver from 'file-saver';
import { Card, Form } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getRequest } from '../../helpers/apiHandlers';
import config from '../../config/constant';
import WrapperCard from '../../components/shared/Cards/WrapperCard';
import Loading from '../../components/shared/Loading/Loading';
import HOC from '../../components/shared/HOC';
import TopupHistoryTable from '../../components/AdminTools/TopupHistoryTable';

const API_BASE_URL = config.urls.api_base_url;

class TopupHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      adminList: [],
      startDate: null,
      endDate: null,
      dateValue: '',
      selectedCompany: [],
      selectedAdmin: [],
      topupHistoryDataList: [],
      currentPage: 1,
      perPage: 10,
      totalData: 0,
      isLoading: false,
      isDownloadLoading: false,
    };

    this.getCompanyList = this.getCompanyList.bind(this);
    this.getAdmins = this.getAdmins.bind(this);
    this.getTopupHistoryData = this.getTopupHistoryData.bind(this);
    this.handleDownloadReport = this.handleDownloadReport.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleSelectCompany = this.handleSelectCompany.bind(this);
    this.handleSelectAdmin = this.handleSelectAdmin.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.getCompanyList();
  }

  companyDataModeling(data) {
    if (data.length > 0) {
      let tempData = [];

      data.map((item) =>
        tempData.push({
          ...item,
          label: item.name,
          value: item.id,
        })
      );

      return tempData;
    }
  }

  adminDataModeling(data) {
    if (data.length > 0) {
      let tempData = [];

      data.map((item) =>
        tempData.push({
          ...item,
          label: item.username,
          value: item.id,
        })
      );

      return tempData;
    }
  }

  getCompanyList() {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/admin/companies', data, (res) => {
      if (res.success) {
        const companyList = this.companyDataModeling(res.data);
        this.setState(
          {
            companyList: companyList,
          },
          () => {
            this.getAdmins();
            this.getTopupHistoryData();
          }
        );
      } else {
        // console.log(res.message);
        this.setState({ isLoading: false });
      }
    });
  }

  getAdmins() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/admin/admins', data, (res) => {
      if (res.success) {
        const adminList =
          res.data.length > 0 ? this.adminDataModeling(res.data) : [];
        this.setState({
          adminList: adminList,
        });
      } else {
        // console.log(res);
        this.setState({ isLoading: false });
      }
    });
  }

  getTopupHistoryData(showData) {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    const companyArray = [],
      adminArray = [];
    let companyString = '',
      adminString = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        page: showData ? 1 : this.state.currentPage,
        perPage: this.state.perPage,
      },
    };

    if (this.state.startDate !== null && this.state.endDate !== null) {
      data.params.startDate = moment(this.state.startDate).toISOString();
      data.params.endDate = moment(this.state.endDate).toISOString();
    }

    if (this.state.selectedCompany.length > 0) {
      this.state.selectedCompany.map((company) =>
        companyArray.push(company.id.toString())
      );
    }

    companyString = companyArray.join(',');

    if (companyString.trim() !== '') {
      data.params.companyId = companyString;
    }

    if (this.state.selectedAdmin.length > 0) {
      this.state.selectedAdmin.map((admin) =>
        adminArray.push(admin.id.toString())
      );
    }

    adminString = adminArray.join(',');

    if (adminString.trim() !== '') {
      data.params.userId = adminString;
    }

    getRequest('/admin/history', data, (res) => {
      if (res.success) {
        this.setState({
          topupHistoryDataList: res.data.contains,
          currentPage: res.data.pagination.currentPage,
          perPage: res.data.pagination.perPage,
          totalData: res.data.pagination.totalData,
          isLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ isLoading: false });
      }
    });
  }

  handleDownloadReport() {
    this.setState({ isDownloadLoading: true });

    const companyArray = [],
      adminArray = [];
    let companyString = '',
      adminString = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {},
    };

    if (this.state.startDate !== null && this.state.endDate !== null) {
      data.params.startDate = moment(this.state.startDate).toISOString();
      data.params.endDate = moment(this.state.endDate).toISOString();
    }

    if (this.state.selectedCompany.length > 0) {
      this.state.selectedCompany.map((company) =>
        companyArray.push(company.id.toString())
      );
    }

    companyString = companyArray.join(',');

    if (companyString.trim() !== '') {
      data.params.companyId = companyString;
    }

    if (this.state.selectedAdmin.length > 0) {
      this.state.selectedAdmin.map((admin) =>
        adminArray.push(admin.id.toString())
      );
    }

    adminString = adminArray.join(',');

    if (adminString.trim() !== '') {
      data.params.userId = adminString;
    }

    axios
      .get(`${API_BASE_URL}/admin/history/download`, data)
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'data:text/csv;charset=utf-8,',
        });

        FileSaver.saveAs(blobData, 'admin-topup-history.csv');
        this.setState({ isDownloadLoading: false });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ isDownloadLoading: false });
      });
  }

  handleDatePicker(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        dateValue: dateValue,
        startDate: p.startDate,
        endDate: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        dateValue: dateValue,
        startDate: null,
        endDate: null,
      });
    }
  }

  handleSelectCompany(e) {
    this.setState({ selectedCompany: e });
  }

  handleSelectAdmin(e) {
    this.setState({ selectedAdmin: e });
  }

  handlePageChange(pageNo) {
    this.setState(
      {
        currentPage: pageNo,
      },
      () => {
        this.getTopupHistoryData();
      }
    );
  }

  render() {
    return (
      <div className="TopupHistory">
        <div className="row">
          <div className="col-12">
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-history',
                  title: 'ADMIN - Top up History',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="dateRange">
                      <Form.Label>Date and Time Range</Form.Label>
                      <DateRangePicker
                        initialSettings={{
                          timePicker: true,
                          locale: {
                            cancelLabel: 'Clear',
                            format: 'DD/MM/YYYY hh:mm A',
                          },
                          autoUpdateInput: false,
                        }}
                        onEvent={(event, picker) =>
                          this.handleDatePicker(event, picker)
                        }
                      >
                        <Form.Control
                          type="text"
                          className="form-control datetime"
                          placeholder="Select Date"
                          aria-invalid="false"
                          autoComplete="off"
                        />
                      </DateRangePicker>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Label>Filter By</Form.Label>
                    <Form.Group controlId="selectCompany">
                      <Select
                        isMulti
                        name="filterBy"
                        placeholder="Select Company"
                        options={this.state.companyList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#2f2f2f',
                            color: '#fff',
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: '#fff',
                          }),
                        }}
                        onChange={(e) => this.handleSelectCompany(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group
                      controlId="shortCodeFilter"
                      className="select-admin"
                    >
                      <Select
                        isMulti
                        name="selectAdmin"
                        placeholder="Select Admin"
                        options={this.state.adminList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#2f2f2f',
                            color: '#fff',
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: '#fff',
                          }),
                        }}
                        onChange={(e) => this.handleSelectAdmin(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
                    <button
                      type="submit"
                      className="btn main-btn"
                      onClick={() => this.getTopupHistoryData(true)}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? (
                        <Loading />
                      ) : (
                        <span>Show Data</span>
                      )}
                    </button>
                  </div>
                  <TopupHistoryTable
                    data={this.state}
                    handlePageChange={this.handlePageChange}
                  />
                  <div className="col-12 d-flex flex-sm-row flex-column">
                    <button
                      type="submit"
                      className="btn main-btn icon-btn"
                      onClick={() => this.handleDownloadReport()}
                      disabled={this.state.isDownloadLoading}
                    >
                      {this.state.isDownloadLoading ? (
                        <Loading />
                      ) : (
                        <HOC>
                          <i className="bx bx-download " />
                          <span>Download report</span>
                        </HOC>
                      )}
                    </button>
                  </div>
                </div>
              </Card.Body>
            </WrapperCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(TopupHistory);
