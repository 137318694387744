import React from 'react';
import { Bar } from 'react-chartjs-2';
import Card from 'react-bootstrap/Card';
import WrapperCard from '../shared/Cards/WrapperCard';
import Loading from '../shared/Loading/Loading';
import HOC from '../shared/HOC';

const AdminReportCharts = ({ data }) => (
  <WrapperCard
    header={{
      exist: true,
      data: {
        iconName: 'bx bx-line-chart',
        title: 'Report Statistics',
      },
    }}
  >
    <Card.Body>
      <div className="col-12">
        <Card.Body>
          <div className="row">
            {data.isSummaryLoading && (
              <div className="col-12">
                <div className="loading-white-bg m-5 p-5">
                  <Loading />
                </div>
              </div>
            )}
            {!data.isSummaryLoading &&
              !data.revenueBarData.gotData &&
              !data.shortCodeBarData.gotData && (
                <div className="col-12">
                  <div className="fallback-img-container">
                    <div className="row">
                      <div className="col-12">
                        <img
                          alt="no-monthly-data"
                          src={
                            require('../../assets/dashboard/data-report.svg')
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {!data.isSummaryLoading &&
              (data.revenueBarData.gotData ||
                data.shortCodeBarData.gotData) && (
                <HOC>
                  <div className="col-12 col-md-6">
                    <Bar
                      data={{
                        labels: data.revenueBarData.labels,
                        datasets: [
                          {
                            label: data.revenueBarData.label,
                            data: data.revenueBarData.data,
                            backgroundColor: data.backgroundColor,
                            borderColor: data.borderColor,
                            borderWidth: data.borderWidth,
                          },
                        ],
                      }}
                      options={data.chartOptions}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Bar
                      data={{
                        labels: data.shortCodeBarData.labels,
                        datasets: [
                          {
                            label: data.shortCodeBarData.label,
                            data: data.shortCodeBarData.data,
                            backgroundColor: data.backgroundColor,
                            borderColor: data.borderColor,
                            borderWidth: data.borderWidth,
                          },
                        ],
                      }}
                      options={data.chartOptions}
                    />
                  </div>
                </HOC>
              )}
          </div>
        </Card.Body>
      </div>
    </Card.Body>
  </WrapperCard>
);

export default AdminReportCharts;
