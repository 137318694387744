import { historyPush } from '../../routes/historyPush';
import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2';
import {
  postRequestWithoutAccessToken,
  putRequest,
  getRequest,
} from '../../helpers/apiHandlers';
import {
  LOGIN_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  LOGOUT,
  UPDATE_LOADING_STATUS,
  UPDATE_STAY_SIGN_IN,
  UPDATE_USER_PROFILE,
  UPDATE_USER_CREDITS,
} from '../actionTypes';

export const setLoadingStatus = (isLoading) => ({
  type: UPDATE_LOADING_STATUS,
  payload: isLoading,
});

const setLoginSuccess = (res) => ({
  type: LOGIN_SUCCESS,
  payload: res,
});

const setStaySignIn = (res) => ({
  type: UPDATE_STAY_SIGN_IN,
  payload: res,
});

// Login Using Email And Password
export const loginUserWithEmailAndPassword = (loginData) => (dispatch) => {
  const data = {
    email: loginData.email,
    password: loginData.password,
    staySignIn: loginData.staySignIn,
  };

  let resMsg = 'Login Successfully';

  dispatch(setLoadingStatus(true));

  postRequestWithoutAccessToken('/login', data, (res) => {
    dispatch(setLoadingStatus(false));
    if (!res.error) {
      dispatch(setLoginSuccess(res.data));
      dispatch(setStaySignIn(data.staySignIn));

      if (res.code !== 200) {
        resMsg = res.message;
      }

      cogoToast.success(resMsg, { position: 'top-right' });
      historyPush('/dashboard');
    } else {
      if (res.message !== 'Account Suspended') {
        cogoToast.error(res.message, { position: 'top-right' });
      }
    }
  });
};

// Set Refresh Token Success [Action Type]
export const setRefreshTokenSuccess = (res) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: res,
});

export const logoutUser = () => ({
  type: LOGOUT,
  payload: {},
});

// Logout and delete session
export const onLogout = () => (dispatch) => {
  dispatch(logoutUser());
  historyPush('/login');
};

const setUserProfile = (res) => ({
  type: UPDATE_USER_PROFILE,
  payload: res,
});

export const updateUserProfile = (data, token) => (dispatch) => {
  dispatch(setLoadingStatus(true));
  putRequest('/me/update-profile', data, token, (res) => {
    dispatch(setLoadingStatus(false));
    if (res.success) {
      dispatch(setUserProfile(res.data));
      Swal.fire(
        'Updated!',
        'Your company and user data have been updated.',
        'success'
      );
    } else {
      Swal.fire(
        'Failed!',
        'Your company and user data failed to be updated.',
        'error'
      );
    }
  });
};

const setUserCredits = (res) => ({
  type: UPDATE_USER_CREDITS,
  payload: res,
});

export const getUserCredits = (token) => (dispatch) => {
  const data = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  dispatch(setLoadingStatus(true));
  getRequest('/me/header', data, (res) => {
    dispatch(setLoadingStatus(false));
    if (res.success) {
      dispatch(setUserCredits(res.data));
    } else {
      // console.log(res);
    }
  });
};
