import React from 'react';
import { Switch, Route } from 'react-router-dom';

/* Routes */
import PrivateRoute from './PrivateRoute';
import UserSettingRoutes from './UserSettingRoutes';
import WebMessagingRoutes from './WebMessagingRoutes';
import AdminToolsRoutes from './AdminToolsRoutes';

/* Components */
import TopNavBar from '../components/TopNavBar/TopNavBar';
import Dashboard from '../layouts/Dashboard/Dashboard';
import Reports from '../layouts/Reports/Reports';
import Page404 from '../components/shared/Page404/Page404';
import Footer from '../layouts/Dashboard/Footer/Footer';

const baseUrl = process.env.PUBLIC_URL;

const Routes = () => (
  <div className="Dashboard-wrapper">
    <TopNavBar />
    <div className="dashboard-container">
      <Switch>
        <PrivateRoute
          exact
          path={`${baseUrl}/dashboard`}
          component={Dashboard}
        />
        <PrivateRoute
          path={`${baseUrl}/dashboard/settings`}
          component={UserSettingRoutes}
        />
        <PrivateRoute
          exact
          path={`${baseUrl}/dashboard/reports`}
          component={Reports}
        />
        <PrivateRoute
          path={`${baseUrl}/dashboard/web-messaging`}
          component={WebMessagingRoutes}
        />
        <PrivateRoute
          path={`${baseUrl}/dashboard/admin-tools`}
          component={AdminToolsRoutes}
        />
        <Route path="*" component={Page404} />
      </Switch>
    </div>
    <Footer />
  </div>
);

export default Routes;
