import React from 'react';

const Footer = () => {
  return (
    <div className="Footer">
      <span>2021 © Globe SMS Messaging</span>
    </div>
  );
};

export default Footer;
