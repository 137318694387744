import React from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { Table } from 'react-bootstrap';
import Loading from '../shared/Loading/Loading';

const TopupHistoryTable = ({ data, handlePageChange }) => (
  <div className="col-12 mt-5">
    <Table responsive>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Company Name</th>
          <th>Top up Amount</th>
          <th>Balance Before</th>
          <th>Balance After</th>
          <th>Top up By</th>
        </tr>
      </thead>
      <tbody>
        {data.isLoading && (
          <tr>
            <td colSpan="6" className="loading-white-bg">
              <Loading />
            </td>
          </tr>
        )}
        {!data.isLoading &&
          data.topupHistoryDataList.length > 0 &&
          data.topupHistoryDataList.map((tHistory) => (
            <tr key={tHistory.id}>
              <td>
                {moment(tHistory.updatedAt).format('DD MMMM YYYY, h:mm:ss a')}
              </td>
              <td>{tHistory.companyName}</td>
              <td>{`RM ${tHistory.amount.toLocaleString()}`}</td>
              <td>{`RM ${tHistory.balanceBefore.toLocaleString()}`}</td>
              <td>{`RM ${tHistory.balanceAfter.toLocaleString()}`}</td>
              <td>{tHistory.username}</td>
            </tr>
          ))}
        {!data.isLoading && data.topupHistoryDataList.length === 0 && (
          <tr className="text-center">
            <td colSpan="6">
              <img
                alt="no-data-found"
                src={require('../../assets/shared/search.svg').default}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
    {data.totalData > 10 && (
      <div className="pagination-custom-style">
        <Pagination
          activePage={data.currentPage}
          itemsCountPerPage={data.perPage}
          totalItemsCount={data.totalData}
          onChange={(e) => handlePageChange(e)}
          hideFirstLastPages
          prevPageText="<PREV"
          nextPageText="NEXT>"
          activeClass="active-page"
          activeLinkClass="active-link"
          itemClass=""
          linkClass="defaul-link"
        />
      </div>
    )}
  </div>
);

export default TopupHistoryTable;
