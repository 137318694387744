import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { historyPush } from '../../routes/historyPush';
import { postRequestWithoutAccessToken } from '../../helpers/apiHandlers';
import {
  loginUserWithEmailAndPassword,
  setLoadingStatus,
  onLogout,
} from '../../store/actions/authAction';
import ForgotPasswordModal from '../../components/Authenticate/ForgotPasswordModal';
import VeficiationModal from '../../components/Authenticate/VerificationModal';
import Loading from '../../components/shared/Loading/Loading';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      staySignIn: false,
      forgotPasswordEmail: '',
      showForgotPassword: false,
      showVerificationModal: false,
      verificationTitle: '',
      verificationMessage: '',

      /* Error Handling */
      emailError: false,
      passwordError: false,
      forgotPasswordEmailError: false,
    };

    this.handleVerificationFlow = this.handleVerificationFlow.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseVerificationModal =
      this.handleCloseVerificationModal.bind(this);
  }

  componentDidMount() {
    if (this.props.location.search === '') {
      if (this.props.auth.isLoggedIn) {
        historyPush('/dashboard');
      } else {
        this.props.setLoadingStatus(false);
      }
    } else {
      if (this.props.auth.isLoggedIn) {
        this.props.onLogout();
      }

      this.handleVerificationFlow();
    }
  }

  componentWillUnmount() {
    this.props.setLoadingStatus(false);
  }

  handleVerificationFlow() {
    const actionParam = new URLSearchParams(this.props.location.search).get(
      'action'
    );

    const statusParam = new URLSearchParams(this.props.location.search).get(
      'status'
    );

    if (actionParam === 'verify') {
      if (statusParam === 'success') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification successful',
          verificationMessage:
            'Your login credentials have been sent to your registered email address.',
        });
      } else if (statusParam === 'token-error') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification failed',
          verificationMessage:
            'Something went wrong. Please try again later or contact your admin.',
        });
      } else if (statusParam === 'exist') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification success',
          verificationMessage:
            'Your account is already verified. Your login credentials have been sent to your registered email address.',
        });
      } else if (statusParam === 'technical-exist') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification failed',
          verificationMessage:
            'An existing technical user has been assigned. Please contact your admin for a new verification link without a technical role.',
        });
      } else {
        historyPush('/dashboard');
      }
    } else if (actionParam === 'password') {
      if (statusParam === 'success') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Reset password successful',
          verificationMessage:
            'Your new password has been sent to your registered email address.',
        });
      } else if (statusParam === 'error') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Reset password failed',
          verificationMessage:
            'Something went wrong. Please try again later or contact your admin.',
        });
      } else if (statusParam === 'expired') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Reset password failed',
          verificationMessage:
            'Reset password link has expired. Please contact your admin.',
        });
      } else {
        historyPush('/dashboard');
      }
    }
  }

  handleUserInput(e) {
    const id = e.target.id;
    const { value, checked } = e.target;

    if (id === 'email') {
      this.setState({ email: value });

      if (this.state.emailError) {
        this.setState({ emailError: false });
      }
    } else if (id === 'password') {
      this.setState({ password: value });

      if (this.state.passwordError) {
        this.setState({ passwordError: false });
      }
    } else if (id === 'staySignIn') {
      this.setState({ staySignIn: checked });
    } else if (id === 'forgotPasswordEmail') {
      this.setState({ forgotPasswordEmail: value });

      if (this.state.forgotPasswordEmailError) {
        this.setState({ forgotPasswordEmailError: false });
      }
    }
  }

  checkVal(obj) {
    if (obj.value.trim() === '') {
      this.setState({
        [obj.fieldName + 'Error']: true,
      });
    }
  }

  handleLogin(e) {
    e.preventDefault();
    const { email, password } = this.state;
    if (email.trim() !== '' && password.trim() !== '') {
      const loginData = {
        email: this.state.email,
        password: this.state.password,
        staySignIn: this.state.staySignIn ? 1 : 0,
      };

      this.props.loginUserWithEmailAndPassword(loginData);
    } else {
      this.checkVal({ fieldName: 'email', value: email });
      this.checkVal({ fieldName: 'password', value: password });
    }
  }

  handleForgotPassword() {
    const { forgotPasswordEmail } = this.state;
    const data = {
      email: forgotPasswordEmail,
    };

    if (forgotPasswordEmail.trim() !== '') {
      postRequestWithoutAccessToken('/forgot-password', data, (res) => {
        if (res.success) {
          cogoToast.success(res.message, { position: 'top-right' });
        } else {
          cogoToast.error(res.message, { position: 'top-right' });
        }
      });
    } else {
      this.checkVal({
        fieldName: 'forgotPasswordEmail',
        value: forgotPasswordEmail,
      });
    }
  }

  handleCloseModal() {
    this.setState({ showForgotPassword: false });
  }

  handleCloseVerificationModal() {
    this.setState({ showVerificationModal: false });
  }

  render() {
    return (
      <div className="Login">
        <div className="login-wrapper">
          <div className="row mx-0 px-0 bg-white">
            <div className="col-lg-6 leftContainer d-flex flex-column">
              <div className="formContainer">
                <div className="row text-center">
                  <div className="col-12 px-0">
                    <div className="login-logo">
                      <img
                        alt="orange-logo"
                        src={
                          require('../../assets/globe-logo-white.png').default
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="loginForm">
                  <div className="row">
                    <div className="col-12 pt-3">
                      <div className="row">
                        <div className="col-12 text-center">
                          <h4>Globe SMS Messaging</h4>
                        </div>
                        <div className="col-12">
                          <div className="text-divider">
                            <small>login with registered email</small>
                          </div>
                        </div>
                        <div className="col-12">
                          <Form onSubmit={this.handleLogin}>
                            <Form.Group controlId="email">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your email"
                                className={
                                  this.state.emailError ? 'is-invalid' : ''
                                }
                                onChange={(e) => this.handleUserInput(e)}
                              />
                              {this.state.emailError && (
                                <Form.Text>Email is required</Form.Text>
                              )}
                            </Form.Group>
                            <Form.Group controlId="password">
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Enter your password"
                                className={
                                  this.state.passwordError ? 'is-invalid' : ''
                                }
                                onChange={(e) => this.handleUserInput(e)}
                              />
                              {this.state.passwordError && (
                                <Form.Text>Password is required</Form.Text>
                              )}
                            </Form.Group>
                            <div className="row">
                              <div className="col-6">
                                <Form.Group controlId="staySignIn">
                                  <Form.Check
                                    id="staySignIn"
                                    type="checkbox"
                                    label="Keep me logged in"
                                    onChange={(e) => this.handleUserInput(e)}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  className="forgot-password"
                                  type="button"
                                  onClick={() =>
                                    this.setState({
                                      showForgotPassword: true,
                                    })
                                  }
                                >
                                  Forgot Password?
                                </button>
                              </div>
                              <div className="col-12">
                                <button
                                  className="btn main-btn w-100"
                                  type="submit"
                                  disabled={this.props.auth.isLoading}
                                  onClick={this.handleLogin}
                                >
                                  {!this.props.auth.isLoading ? (
                                    <span>Login</span>
                                  ) : (
                                    <Loading />
                                  )}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 rightContainer">
              <img
                alt="login-right"
                src={require('../../assets/auth/login.svg').default}
              />
            </div>
          </div>
        </div>
        <ForgotPasswordModal
          showForgotPassword={this.state.showForgotPassword}
          handlers={{
            handleCloseModal: this.handleCloseModal,
            handleUserInput: this.handleUserInput,
            handleForgotPassword: this.handleForgotPassword,
          }}
          forgotPasswordEmailError={this.state.forgotPasswordEmailError}
        />
        <VeficiationModal
          showVerificationModal={this.state.showVerificationModal}
          handleCloseModal={this.handleCloseVerificationModal}
          verificationTitle={this.state.verificationTitle}
          verificationMessage={this.state.verificationMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginUserWithEmailAndPassword: (loginData) =>
    dispatch(loginUserWithEmailAndPassword(loginData)),
  setLoadingStatus: (isLoading) => dispatch(setLoadingStatus(isLoading)),
  onLogout: () => dispatch(onLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
