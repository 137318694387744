import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../../helpers/apiHandlers';
import {
  Card,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { historyPush } from '../../routes/historyPush';
import config from '../../config/constant';
import WrapperCard from '../../components/shared/Cards/WrapperCard';
import Loading from '../../components/shared/Loading/Loading';
import SendSMSDocumentation from '../../components/UserSettings/SendSMSDocumentation';

const API_BASE_URL = config.urls.api_base_url;

const swalCustomConfirmationButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn main-btn ml-3',
    cancelButton: 'btn inverse-btn',
  },
  buttonsStyling: false,
});

class Developer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      apiKey: '',
      callBackURL: '',
      ip: '',
      whitelistedIP: [],
      isLoading: false,
      isCallBackLoading: false,
      isIPLoading: false,

      /* Error Handling */
      callBackURLError: false,
      ipError: false,
    };

    this.checkTechnical = this.checkTechnical.bind(this);
    this.getDevelopersData = this.getDevelopersData.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.checkVal = this.checkVal.bind(this);
    this.handleUpdateCallback = this.handleUpdateCallback.bind(this);
    this.handleAddIPAddress = this.handleAddIPAddress.bind(this);
    this.handleDeleteIPAddress = this.handleDeleteIPAddress.bind(this);
  }

  componentDidMount() {
    this.checkTechnical();
  }

  checkTechnical() {
    if (this.props.auth.user.technical === 1) {
      this.getDevelopersData();
    } else {
      historyPush('/dashboard');
    }
  }

  getDevelopersData() {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/developer', data, (res) => {
      if (res.success) {
        this.setState({
          whitelistedIP: res.data.whitelists,
          uuid: res.data.uuid,
          apiKey: res.data.apikey,
          callBackURL: res.data.callback,
          isLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ isLoading: false });
      }
    });
  }

  handleUserInput(e) {
    const { id, value } = e.target;

    this.setState({ [id]: value }, () => {
      if (id === 'callBackURL') {
        if (this.state.callBackURLError) {
          this.setState({
            callBackURLError: false,
          });
        }
      }

      if (id === 'ip') {
        if (this.state.ipError) {
          this.setState({
            ipError: false,
          });
        }
      }
    });
  }

  checkVal(fieldName, value) {
    if (value.trim() === '') {
      this.setState({
        [fieldName + 'Error']: true,
      });
    }
  }

  handleUpdateCallback() {
    const { callBackURL } = this.state;

    if (callBackURL.trim() !== '') {
      this.setState({ isCallBackLoading: true });

      const data = {
        callback: callBackURL,
      };

      putRequest('/developer/callback', data, this.props.auth.token, (res) => {
        this.setState({ isCallBackLoading: false });
        if (res.success) {
          Swal.fire('Success!', 'Callback URL has been updated.', 'success');
          this.getDevelopersData();
        } else {
          Swal.fire(
            'Failed!',
            `Callback URL was not updated. <br /> ${res.message}`,
            'error'
          );
        }
      });
    } else {
      this.checkVal('callBackURL', callBackURL);
    }
  }

  handleAddIPAddress() {
    const { ip } = this.state;

    if (ip.trim() !== '') {
      this.setState({ isIPLoading: true });

      const data = {
        ip: ip,
      };

      postRequest(
        '/developer/whitelist',
        data,
        this.props.auth.token,
        (res) => {
          this.setState({ isIPLoading: false });
          if (res.success) {
            Swal.fire(
              'Success!',
              'IP address is pending for verification',
              'success'
            );
            this.getDevelopersData();
          } else {
            Swal.fire('Failed!', res.message, 'error');
          }
        }
      );
    } else {
      this.checkVal('ip', ip);
    }
  }

  handleDeleteIPAddress(id, status) {
    if (status !== 'pending') {
      this.setState({ isIPLoading: true });

      const data = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
        },
      };

      swalCustomConfirmationButtons
        .fire({
          title: 'Are you sure?',
          text: 'Removing this IP address will be submitted for approval.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No, keep it',
          confirmButtonText: 'Yes, Submit',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteRequest(`/developer/whitelist/${id}`, data, (res) => {
              if (res.success) {
                this.getDevelopersData(true);
              } else {
                swalCustomConfirmationButtons.fire(
                  'Failed!',
                  `IP Address was not deleted. <br /> ${res.message}`,
                  'error'
                );
              }
            });
          }
          this.setState({ isIPLoading: false });
        });
    }
  }

  render() {
    return (
      <div className="Developer">
        <div className="row">
          <div className="col-12">
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-code-block',
                  title: 'Developer Area',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <Form.Label>API Parameters</Form.Label>
                      </div>
                      <div className="col-4">
                        <Form.Group>
                          <span>API Endpoint Base URL</span>
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <Form.Group>
                          <span>{API_BASE_URL}</span>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group>
                          <span>Company ID</span>
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <Form.Group>
                          {this.state.isLoading ? (
                            <div className="loading-white-bg">
                              <Loading />
                            </div>
                          ) : (
                            <span>{this.state.uuid}</span>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group>
                          <span>API Key</span>
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <Form.Group>
                          {this.state.isLoading ? (
                            <div className="loading-white-bg">
                              <Loading />
                            </div>
                          ) : (
                            <span>{this.state.apiKey}</span>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group>
                          <span>Callback URL</span>
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <InputGroup
                          className={this.state.callBackURLError ? '' : 'mb-3'}
                        >
                          <FormControl
                            id="callBackURL"
                            placeholder="https://"
                            aria-label="https://"
                            aria-describedby="basic-addon2"
                            className={
                              this.state.callBackURLError ? 'is-invalid' : ''
                            }
                            value={this.state.callBackURL}
                            onChange={(e) => this.handleUserInput(e)}
                          />
                          <InputGroup.Append>
                            <button
                              className="btn main-btn"
                              onClick={() => this.handleUpdateCallback()}
                              disabled={this.state.isCallBackLoading}
                            >
                              {this.state.isCallBackLoading ? (
                                <Loading />
                              ) : (
                                <span>Update</span>
                              )}
                            </button>
                          </InputGroup.Append>
                        </InputGroup>
                        {this.state.callBackURLError && (
                          <Form.Group>
                            <Form.Text>Callback URL is required</Form.Text>
                          </Form.Group>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group>
                          <span>Server IP Address</span>
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <InputGroup
                          className={this.state.ipError ? '' : 'mb-3'}
                        >
                          <FormControl
                            id="ip"
                            placeholder="IP Address (Max 3 IPs)"
                            aria-label="IP Address (Max 3 IPs)"
                            aria-describedby="basic-addon2"
                            className={this.state.ipError ? 'is-invalid' : ''}
                            value={this.state.ip}
                            onChange={(e) => this.handleUserInput(e)}
                          />
                          <InputGroup.Append>
                            <button
                              className="btn main-btn"
                              onClick={() => this.handleAddIPAddress()}
                              disabled={this.state.isIPLoading}
                            >
                              {this.state.isIPLoading ? (
                                <Loading />
                              ) : (
                                <span>Add</span>
                              )}
                            </button>
                          </InputGroup.Append>
                        </InputGroup>
                        {this.state.ipError && (
                          <Form.Group>
                            <Form.Text>Server IP Address is required</Form.Text>
                          </Form.Group>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group>
                          <span>Whitelisted IP's</span>
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <div className="col-6">
                            {this.state.whitelistedIP.length > 0 &&
                              this.state.whitelistedIP.map((whitelist) => (
                                <div key={whitelist.id} className="whitelist">
                                  <span>{whitelist.ipAddress}</span>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        {whitelist.status === 'ok'
                                          ? 'Remove IP'
                                          : 'Pending verification'}
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      className={
                                        'bx ' +
                                        (whitelist.status === 'ok'
                                          ? 'bx-trash'
                                          : 'bx-timer')
                                      }
                                      onClick={() =>
                                        this.handleDeleteIPAddress(
                                          whitelist.id,
                                          whitelist.status
                                        )
                                      }
                                    />
                                  </OverlayTrigger>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </WrapperCard>
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-code-curly',
                  title: 'API Documentation',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12">
                    <div className="documentation">
                      <div className="row">
                        <div className="col-12">
                          <Form.Label>
                            Here are the authentication process to access the
                            API:
                          </Form.Label>
                          <ol>
                            <li>
                              Concatenate value of <code>companyId</code> +{' '}
                              <code>apikey</code> with PIPE DELIMITER.
                            </li>
                            <li>
                              Encrypt the concatenated string based using
                              Base64.
                            </li>
                            <li>
                              Create new Header key: <code>apikey</code> in your
                              API request, and put your Encrypted string as the
                              value.
                            </li>
                          </ol>
                        </div>
                        <div className="col-12">
                          <Form.Label>
                            Hash Calculation Sample Testing:
                            <br />
                            Let's say we have
                            <br />
                            <br />
                            Company ID ={' '}
                            <code>'233b1b08-0643-4722-a5d6-c521def829f0'</code>
                            ,
                            <br />
                            <br />
                            API Key ={' '}
                            <code>'7w48cYN1tVLBmsN7rgXQyJ1tyrnz5yMJoeg'</code>
                            ,
                            <br />
                            <br />
                            API Secret ={' '}
                            <code>
                              'Ym2TGTgvpnT47mNCdn3wDllKhmfGj2KssnRKIIp2U7w=',
                            </code>
                            ,
                            <br />
                            <br />
                            Concatenated String ={' '}
                            <code>
                              233b1b08-0643-4722-a5d6-c521def829f0|7w48cYN1tVLBmsN7rgXQyJ1tyrnz5yMJoeg
                            </code>
                            ,
                            <br />
                            <br />
                            Hash Result ={' '}
                            <code>
                              MjMzYjFiMDgtMDY0My00NzIyLWE1ZDYtYzUyMWRlZjgyOWYwfDd3NDhjWU4xdFZMQm1zTjdyZ1hReUoxdHlybno1eU1Kb2Vn
                            </code>
                            ,
                            <br />
                            <br />
                            If the result is like the above sample, then your
                            code should be correct.
                            <br />
                            <br />
                          </Form.Label>
                        </div>
                        <SendSMSDocumentation />
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </WrapperCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Developer);
