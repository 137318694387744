import React, { Component } from 'react';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  getRequest,
  postRequest,
  postMultipartRequest,
  deleteRequest,
} from '../../helpers/apiHandlers';
import ContactManagement from '../../components/WebMessaging/ContactManagement';
import GroupManagement from '../../components/WebMessaging/GroupManagement';
import AddContactModal from '../../components/WebMessaging/AddContactModal';
import AddContactGroupModal from '../../components/WebMessaging/AddContactGroupModal';

const swalCustomConfirmationButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn main-btn ml-3',
    cancelButton: 'btn inverse-btn',
  },
  buttonsStyling: false,
});

class ContactManagements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddContactModal: false,
      showAddGroupContactModal: false,
      contactName: '',
      contactNumber: '',
      startDateContact: null,
      endDateContact: null,
      contactDateValue: '',
      startDateGroup: null,
      endDateGroup: null,
      groupDateValue: '',
      userName: '',
      phoneNumber: '',
      contactList: [],
      groupNameList: [],
      groupListFilter: [],
      selectedGroupFilterContact: [],
      selectedGroupFilterGroup: [],
      selectedGroupName: '',
      bulkUploadContact: false,
      bulkFile: undefined,
      newGroupName: '',
      contactPagination: {
        currentPage: 1,
        perPage: 10,
        totalData: 0,
        totalPage: 0,
      },
      groupPagination: {
        currentPage: 1,
        perPage: 10,
        totalData: 0,
        totalPage: 0,
      },
      addContactLoading: false,
      addGroupLoading: false,
      showContactLoading: false,
      showGroupLoading: false,

      /* Error Handling */
      userNameError: false,
      phoneNumberError: false,
      selectedGroupNameError: false,
      bulkFileError: false,
      newGroupNameError: false,
    };

    this.handleUserInput = this.handleUserInput.bind(this);
    this.dataModeling = this.dataModeling.bind(this);
    this.getGroupListData = this.getGroupListData.bind(this);
    this.handleShowDataContacts = this.handleShowDataContacts.bind(this);
    this.handleShowDataGroups = this.handleShowDataGroups.bind(this);
    this.handleDeleteContact = this.handleDeleteContact.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.handleAddNewContact = this.handleAddNewContact.bind(this);
    this.handleAddNewGroupContact = this.handleAddNewGroupContact.bind(this);
    this.handleShowAddContactModal = this.handleShowAddContactModal.bind(this);
    this.handleShowAddGroupContactModal =
      this.handleShowAddGroupContactModal.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
    this.handleDatePickerContact = this.handleDatePickerContact.bind(this);
    this.handleDatePickerGroup = this.handleDatePickerGroup.bind(this);
    this.handleGroupFilterContact = this.handleGroupFilterContact.bind(this);
    this.handleGroupFilterGroup = this.handleGroupFilterGroup.bind(this);
    this.handleContactPageChange = this.handleContactPageChange.bind(this);
    this.handleGroupPageChange = this.handleGroupPageChange.bind(this);
    this.resetStates = this.resetStates.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.handleSelectFile = this.handleSelectFile.bind(this);
  }

  componentDidMount() {
    this.getGroupListData();
  }

  handleUserInput(e) {
    const id = e.target.id;
    const { value, checked, files } = e.target;

    if (id === 'contactName') {
      this.setState({ contactName: value });
    } else if (id === 'contactNumber') {
      this.setState({ contactNumber: value });
    } else if (id === 'userName') {
      this.setState({ userName: value });

      if (this.state.userNameError) {
        this.setState({ userNameError: false });
      }
    } else if (id === 'phoneNumber') {
      this.setState({ phoneNumber: value });

      if (this.state.phoneNumberError) {
        this.setState({ phoneNumberError: false });
      }
    } else if (id === 'selectedGroupName') {
      this.setState({ selectedGroupName: value });

      if (this.state.selectedGroupNameError) {
        this.setState({ selectedGroupNameError: false });
      }
    } else if (id === 'bulkUploadContact') {
      this.setState({ bulkUploadContact: checked }, () => this.clearErrors());
    } else if (id === 'bulkFile') {
      if (
        files[0].type === 'text/plain' ||
        files[0].type === 'application/vnd.ms-excel'
      ) {
        this.setState({ bulkFile: files[0] });
      } else {
        this.setState({ bulkFile: undefined });
        cogoToast.error('Invalid file format', { position: 'top-right' });
      }

      if (this.state.bulkFileError) {
        this.setState({ bulkFileError: false });
      }
    } else if (id === 'newGroupName') {
      this.setState({ newGroupName: value });

      if (this.state.newGroupNameError) {
        this.setState({ newGroupNameError: false });
      }
    }
  }

  dataModeling(data) {
    if (data.length > 0) {
      let tempData = [];

      data.map((item) =>
        tempData.push({
          ...item,
          label: item.name,
          value: item.id,
        })
      );

      return tempData;
    }
  }

  getGroupListData() {
    this.setState({ showGroupLoading: true, showContactLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/groups', data, (res) => {
      if (res.success) {
        const groupListFilter =
          res.data.groups.length > 0 ? this.dataModeling(res.data.groups) : [];
        this.setState(
          {
            groupListFilter: groupListFilter,
          },
          () => {
            this.handleShowDataGroups(true);
            this.handleShowDataContacts();
          }
        );
      } else {
        // console.log(res);
        this.setState({ showGroupLoading: false, showContactLoading: false });
      }
    });
  }

  handleShowDataContacts(showData) {
    this.setState({ showContactLoading: true });

    const groupArray = [];
    let groupStrings = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        page: showData ? 1 : this.state.contactPagination.currentPage,
        perPage: this.state.contactPagination.perPage,
      },
    };

    if (
      this.state.startDateContact !== null &&
      this.state.endDateContact !== null
    ) {
      data.params.startDate = moment(this.state.startDateContact).toISOString();
      data.params.endDate = moment(this.state.endDateContact).toISOString();
    }

    if (this.state.selectedGroupFilterContact.length > 0) {
      this.state.selectedGroupFilterContact.map((group) =>
        groupArray.push(group.id.toString())
      );
    }

    groupStrings = groupArray.join(',');

    if (groupStrings.trim() !== '') {
      data.params.groupId = groupStrings;
    }

    if (this.state.contactName.trim() !== '') {
      data.params.name = this.state.contactName;
    }

    if (this.state.contactNumber.trim() !== '') {
      data.params.phone = this.state.contactNumber;
    }

    getRequest('/contacts', data, (res) => {
      if (res.success) {
        const contactList =
          res.data.contacts.length > 0
            ? this.dataModeling(res.data.contacts)
            : [];
        this.setState({
          contactList: contactList,
          contactPagination: res.data.pagination,
          showContactLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ showContactLoading: false });
      }
    });
  }

  handleShowDataGroups(showData) {
    this.setState({ showGroupLoading: true });

    const groupArray = [];
    let groupStrings = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        paginate: true,
        page: showData ? 1 : this.state.groupPagination.currentPage,
        perPage: this.state.groupPagination.perPage,
      },
    };

    if (
      this.state.startDateGroup !== null &&
      this.state.endDateGroup !== null
    ) {
      data.params.startDate = moment(this.state.startDateGroup).toISOString();
      data.params.endDate = moment(this.state.endDateGroup).toISOString();
    }

    if (this.state.selectedGroupFilterGroup.length > 0) {
      this.state.selectedGroupFilterGroup.map((group) =>
        groupArray.push(group.id.toString())
      );
    }

    groupStrings = groupArray.join(',');

    if (groupStrings.trim() !== '') {
      data.params.groupId = groupStrings;
    }

    getRequest('/groups', data, (res) => {
      if (res.success) {
        const groupNameList =
          res.data.groups.length > 0 ? this.dataModeling(res.data.groups) : [];
        this.setState({
          groupNameList: groupNameList,
          groupPagination: res.data.pagination,
          showGroupLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ showGroupLoading: false });
      }
    });
  }

  handleDeleteContact(id) {
    this.setState({ showContactLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    swalCustomConfirmationButtons
      .fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover it after this!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, keep it',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteRequest(`/contact/${id}`, data, (res) => {
            if (res.success) {
              swalCustomConfirmationButtons.fire(
                'Success!',
                'The contact have been deleted.',
                'success'
              );
              this.handleShowDataContacts(true);
            } else {
              swalCustomConfirmationButtons.fire(
                'Failed!',
                `The contact was not deleted. <br /> ${res.message}`,
                'error'
              );
            }
          });
        }
        this.setState({ showContactLoading: false });
      });
  }

  handleDeleteGroup(id) {
    this.setState({ showGroupLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    swalCustomConfirmationButtons
      .fire({
        title: 'Are you sure?',
        text: 'All contacts that belongs to this group will be deleted!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, keep it',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteRequest(`/group/${id}`, data, (res) => {
            if (res.success) {
              swalCustomConfirmationButtons.fire(
                'Success!',
                'The group have been deleted.',
                'success'
              );
              this.getGroupListData(true);
            } else {
              swalCustomConfirmationButtons.fire(
                'Failed!',
                'The group was not deleted.',
                'error'
              );
            }
          });
        }
        this.setState({ showGroupLoading: false });
      });
  }

  checkVal(fieldName, value) {
    if (value.trim() === '') {
      this.setState({
        [fieldName + 'Error']: true,
      });
    }
  }

  handleAddNewContact() {
    const {
      userName,
      phoneNumber,
      selectedGroupName,
      bulkUploadContact,
      bulkFile,
    } = this.state;

    if (!bulkUploadContact) {
      if (
        userName.trim() !== '' &&
        phoneNumber.trim() !== '' &&
        selectedGroupName.toString().trim() !== ''
      ) {
        this.setState({ addContactLoading: true });

        const data = {
          name: userName,
          phone: phoneNumber,
          groupId: selectedGroupName,
        };

        postRequest('/contact', data, this.props.auth.token, (res) => {
          this.setState({ addContactLoading: false });

          if (res.success) {
            Swal.fire('Success!', 'Contact(s) have been added.', 'success');
            this.handleShowDataContacts(true);
            this.handleShowDataGroups(true);
          } else {
            Swal.fire(
              'Failed!',
              `Contact(s) was not added. <br /> ${res.message}`,
              'error'
            );
          }
          if (res.code !== 403 && res.message !== 'contact already exist') {
            this.handleShowAddContactModal();
          }
        });
      } else {
        this.checkVal('userName', userName);
        this.checkVal('phoneNumber', phoneNumber);
        this.checkVal('selectedGroupName', selectedGroupName.toString());
      }
    } else {
      if (
        selectedGroupName.toString().trim() !== '' &&
        bulkFile !== undefined
      ) {
        this.setState({ addContactLoading: true });

        const formData = new FormData();
        formData.append('groupId', selectedGroupName);
        formData.append('contactData', bulkFile);

        postMultipartRequest(
          '/contact/bulk',
          formData,
          this.props.auth.token,
          (res) => {
            this.setState({ addContactLoading: false });

            if (res.success) {
              Swal.fire(
                'Success!',
                'Uploading is in progress. You will receive a notification on your registered email if an error has been encountered.',
                'success'
              );
              this.handleShowDataContacts(true);
              this.handleShowDataGroups(true);
            } else {
              Swal.fire(
                'Failed!',
                `Contact(s) was not added. <br /> ${res.message}`,
                'error'
              );
            }
            this.handleShowAddContactModal();
          }
        );
      } else {
        this.checkVal('selectedGroupName', selectedGroupName.toString());
        this.checkVal('bulkFile', bulkFile === undefined ? '' : bulkFile.name);
      }
    }
  }

  handleAddNewGroupContact() {
    const { newGroupName } = this.state;
    if (newGroupName.trim() !== '') {
      this.setState({ addGroupLoading: true });

      const data = {
        name: newGroupName,
      };

      postRequest('/group', data, this.props.auth.token, (res) => {
        this.setState({ addGroupLoading: false });

        if (res.success) {
          Swal.fire('Success!', 'Group have been added.', 'success');
          this.getGroupListData();
        } else {
          Swal.fire(
            'Failed!',
            `Group was not added. <br /> ${res.message}`,
            'error'
          );
        }
        if (res.code !== 400 && res.message !== 'Group name already exist') {
          this.handleShowAddGroupContactModal();
        }
      });
    } else {
      this.checkVal('newGroupName', newGroupName);
    }
  }

  handleShowAddContactModal() {
    this.setState(
      {
        showAddContactModal: !this.state.showAddContactModal,
      },
      () => {
        if (!this.state.showAddContactModal) {
          this.resetStates();
        }
      }
    );
  }

  handleShowAddGroupContactModal() {
    this.setState(
      {
        showAddGroupContactModal: !this.state.showAddGroupContactModal,
      },
      () => {
        if (!this.state.showAddGroupContactModal) {
          this.resetStates();
        }
      }
    );
  }

  handleSelectGroup(e) {
    this.setState({ selectedGroupName: e.value });

    if (this.state.selectedGroupNameError) {
      this.setState({ selectedGroupNameError: false });
    }
  }

  handleDatePickerContact(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        contactDateValue: dateValue,
        startDateContact: p.startDate,
        endDateContact: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        contactDateValue: dateValue,
        startDateContact: null,
        endDateContact: null,
      });
    }
  }

  handleDatePickerGroup(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        groupDateValue: dateValue,
        startDateGroup: p.startDate,
        endDateGroup: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        groupDateValue: dateValue,
        startDateGroup: null,
        endDateGroup: null,
      });
    }
  }

  handleGroupFilterContact(e) {
    this.setState({
      selectedGroupFilterContact: e,
    });
  }

  handleGroupFilterGroup(e) {
    this.setState({
      selectedGroupFilterGroup: e,
    });
  }

  handleContactPageChange(pageNo) {
    this.setState(
      (prevState) => ({
        contactPagination: {
          ...prevState.contactPagination,
          currentPage: pageNo,
        },
      }),
      () => {
        this.handleShowDataContacts();
      }
    );
  }

  handleGroupPageChange(pageNo) {
    this.setState(
      (prevState) => ({
        groupPagination: {
          ...prevState.groupPagination,
          currentPage: pageNo,
        },
      }),
      () => {
        this.handleShowDataGroups();
      }
    );
  }

  resetStates() {
    this.setState({
      contactName: '',
      contactNumber: '',
      userName: '',
      phoneNumber: '',
      selectedGroupName: '',
      bulkUploadContact: false,
      bulkFile: undefined,
      newGroupName: '',
      userNameError: false,
      phoneNumberError: false,
      selectedGroupNameError: false,
      bulkFileError: false,
      newGroupNameError: false,
    });
  }

  clearErrors() {
    this.setState({
      userNameError: false,
      phoneNumberError: false,
      selectedGroupNameError: false,
      bulkFileError: false,
      newGroupNameError: false,
    });
  }

  handleSelectFile() {
    if (!this.state.bulkUploadContact) {
      this.setState({ bulkUploadContact: true }, () => this.clearErrors());
    }
  }

  render() {
    const {
      handleUserInput,
      handleShowAddContactModal,
      handleAddNewContact,
      handleShowAddGroupContactModal,
      handleAddNewGroupContact,
      handleSelectGroup,
      handleDatePickerContact,
      handleDatePickerGroup,
      handleGroupFilterContact,
      handleGroupFilterGroup,
      handleShowDataContacts,
      handleShowDataGroups,
      handleContactPageChange,
      handleGroupPageChange,
      handleDeleteContact,
      handleDeleteGroup,
      handleSelectFile,
    } = this;

    return (
      <div className="ContactManagements">
        <div className="row">
          <div className="col-12">
            <ContactManagement
              data={this.state}
              handlers={{
                handleDatePickerContact,
                handleShowAddContactModal,
                handleGroupFilterContact,
                handleUserInput,
                handleShowDataContacts,
                handleContactPageChange,
                handleDeleteContact,
              }}
            />
          </div>
          <div className="col-12">
            <GroupManagement
              data={this.state}
              handlers={{
                handleDatePickerGroup,
                handleShowAddGroupContactModal,
                handleGroupFilterGroup,
                handleUserInput,
                handleShowDataGroups,
                handleGroupPageChange,
                handleDeleteGroup,
              }}
            />
          </div>
        </div>
        <AddContactModal
          data={this.state}
          handlers={{
            handleUserInput,
            handleAddNewContact,
            handleShowAddContactModal,
            handleSelectGroup,
            handleSelectFile,
          }}
        />
        <AddContactGroupModal
          data={this.state}
          handlers={{
            handleUserInput,
            handleShowAddGroupContactModal,
            handleAddNewGroupContact,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ContactManagements);
