import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const baseUrl = process.env.PUBLIC_URL;

const Topbar = (props) => (
  <div className="Topbar">
    <div className="leftSide">
      <div className="nav-mobile">
        <i
          className="bx bx-list-ul"
          onClick={() => props.handlers.handleShowMobileNav()}
        />
      </div>
      <div className="hideShowNavName">
        <i
          className="bx bx-list-ul"
          onClick={() => props.handlers.handleHideNavName()}
        />
      </div>
      <div className="plan">
        <i className="bx bx-package" />
        <span>{props.data.creditType}</span>
      </div>
      <div className="wallet-quota">
        <i className="bx bx-wallet-alt" />
        <span>
          {props.data.creditType === 'prepaid'
            ? `RM ${props.data.balance.toLocaleString()}`
            : props.data.balance}
        </span>
      </div>
    </div>
    <div className="middle">
      <img
        alt="umobile-logo-white"
        src={require('../../../assets/globe-logo-white.png').default}
      />
      <h2>Globe SMS Messaging</h2>
    </div>
    <div className="rightSide">
      <span>{props.data.companyName}</span>
      <Dropdown
        id="topbar-dropdown"
        show={props.data.showTopbar}
        onMouseEnter={props.handlers.showTopbarDropdown}
        onMouseLeave={props.handlers.hideTopbarDropdown}
      >
        <Dropdown.Toggle as="div">
          <div className="user-profile">
            <div
              className={
                'name-initials ' +
                // (this.props.user.profile_picture_url ? 'd-none' : '')
                (false ? 'd-none' : '')
              }
            >
              {props.data.userInitials}
            </div>
            <div
              className={
                'userProfileImg ' +
                // (this.props.user.profile_picture_url ? '' : 'd-none')
                (false ? '' : 'd-none')
              }
            >
              <img
                // src={this.props.user.profile_picture_url}
                src={require('../../../assets/topbar/cyclist.jpg').default}
                alt="Avatar"
              />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <NavLink
            activeClassName="navItemActive"
            to={`${baseUrl}/dashboard/settings/my-account`}
          >
            <Dropdown.Item as="span">
              <i className="bx bx-user" />
              Edit Profile
            </Dropdown.Item>
          </NavLink>
          <div className="dropdown-divider" />
          <Dropdown.Item onClick={() => props.handlers.handleLogout()}>
            <i className="bx bx-power-off" />
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </div>
);

export default Topbar;
