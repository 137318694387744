import React from 'react';
import Form from 'react-bootstrap/Form';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseOnlyFooter } from '../shared/Modals/Footers/FooterFunctions';
import Loading from '../shared/Loading/Loading';

const UserDetailsModal = (props) => (
  <WrapperModal
    show={props.data.showUserDetailsModal}
    onClose={props.handlers.handleShowUserDetailsModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'User Details',
            handleCloseModal: props.handlers.handleShowUserDetailsModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseOnlyFooter
          data={{
            handleCloseModal: props.handlers.handleShowUserDetailsModal,
            isLoading: props.data.companyLoading,
          }}
        />
      ),
    }}
    centered={true}
  >
    <div className="row">
      <div className="col-12">
        {props.data.userDetailsLoading && (
          <div className="modal-loading">
            <Loading />
          </div>
        )}
        {!props.data.userDetailsLoading && (
          <Form>
            <div className="row">
              <div className="col-12">
                <Form.Group controlId="userName">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={props.data.selectedUser.username}
                    disabled={true}
                  />
                  <Form.Label>
                    (Registered at {props.data.selectedUser.createdAt})
                  </Form.Label>
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group controlId="userEmail">
                  <Form.Label>User Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={props.data.selectedUser.email}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={props.data.selectedUser.phone}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group controlId="skypeID">
                  <Form.Label>Skype ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={props.data.selectedUser.skype}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group controlId="companyName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={props.data.selectedUser.companyId.name}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group controlId="address">
                  <Form.Label>Company Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={props.data.selectedUser.companyId.address}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group controlId="cost">
                  <Form.Label>Cost/SMS (MYR)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="0.06"
                    disabled={true}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  </WrapperModal>
);

export default UserDetailsModal;
