import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const baseUrl = process.env.PUBLIC_URL;

const NavbarMenu = (props) => (
  <div className="Navbar">
    <div
      className="flex-container"
      id={props.data.showMobileNav ? 'hidden' : ''}
    >
      <NavLink
        exact
        className="navItem"
        activeClassName="navItemActive"
        to={`${baseUrl}/dashboard`}
        onClick={() => props.handlers.handleShowMobileNav()}
      >
        <i className="bx bx-chalkboard" />
        {!props.data.hideNavName && <span>Dashboard</span>}
      </NavLink>
      <Dropdown
        id="nav-dropdown"
        show={props.data.showSettings}
        onMouseEnter={props.handlers.showSettingsDropdown}
        onMouseLeave={props.handlers.hideSettingsDropdown}
      >
        <Dropdown.Toggle as="div">
          <NavLink
            className={
              'navItem dropdown ' +
              (props.data.userTechnical === 0 ? 'not-dev' : '')
            }
            activeClassName="navItemActive"
            to={`${baseUrl}/dashboard/settings`}
          >
            <i className="bx bx-cog" />
            {!props.data.hideNavName && <span>Settings</span>}
            {!props.data.hideNavName && <i className="bx bx-caret-down" />}
          </NavLink>
        </Dropdown.Toggle>
        {props.data.userTechnical === 1 && (
          <Dropdown.Menu>
            <NavLink
              activeClassName="navItemActive"
              to={`${baseUrl}/dashboard/settings/my-account`}
              onClick={() => props.handlers.handleShowMobileNav()}
            >
              <Dropdown.Item as="span">
                <i className="bx bx-right-arrow-alt" />
                My Account
              </Dropdown.Item>
            </NavLink>
            <NavLink
              activeClassName="navItemActive"
              to={`${baseUrl}/dashboard/settings/developer-settings`}
              onClick={() => props.handlers.handleShowMobileNav()}
            >
              <Dropdown.Item as="span">
                <i className="bx bx-right-arrow-alt" />
                Developer
              </Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        )}
      </Dropdown>
      <NavLink
        className="navItem"
        activeClassName="navItemActive"
        to={`${baseUrl}/dashboard/reports`}
        onClick={() => props.handlers.handleShowMobileNav()}
      >
        <i className="bx bx-file" />
        {!props.data.hideNavName && <span>Reports</span>}
      </NavLink>
      <Dropdown
        id="nav-dropdown"
        show={props.data.showWebMessaging}
        onMouseEnter={props.handlers.showWebMessagingDropdown}
        onMouseLeave={props.handlers.hideWebMessagingDropdown}
      >
        <Dropdown.Toggle as="div">
          <NavLink
            className="navItem"
            activeClassName="navItemActive"
            to={`${baseUrl}/dashboard/web-messaging`}
            onClick={() => props.handlers.handleShowMobileNav()}
          >
            <i className="bx bx-message-detail" />
            {!props.data.hideNavName && <span>Web Messaging</span>}
            {!props.data.hideNavName && <i className="bx bx-caret-down" />}
          </NavLink>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <NavLink
            activeClassName="navItemActive"
            to={`${baseUrl}/dashboard/web-messaging/contact-management`}
            onClick={() => props.handlers.handleShowMobileNav()}
          >
            <Dropdown.Item as="span">
              <i className="bx bx-right-arrow-alt" />
              Contact Management
            </Dropdown.Item>
          </NavLink>
          <NavLink
            activeClassName="navItemActive"
            to={`${baseUrl}/dashboard/web-messaging/send-message`}
            onClick={() => props.handlers.handleShowMobileNav()}
          >
            <Dropdown.Item as="span">
              <i className="bx bx-right-arrow-alt" />
              Send Message
            </Dropdown.Item>
          </NavLink>
        </Dropdown.Menu>
      </Dropdown>

      {props.data.userStatus === 'admin' && (
        <Dropdown
          id="nav-dropdown"
          show={props.data.showAdminTools}
          onMouseEnter={props.handlers.showAdminToolsDropdown}
          onMouseLeave={props.handlers.hideAdminToolsDropdown}
        >
          <Dropdown.Toggle as="div">
            <NavLink
              className="navItem"
              activeClassName="navItemActive"
              to={`${baseUrl}/dashboard/admin-tools`}
              onClick={() => props.handlers.handleShowMobileNav()}
            >
              <i className="bx bx-edit" />
              {!props.data.hideNavName && (
                <span className="dropdown">Admin Tools</span>
              )}
              {!props.data.hideNavName && <i className="bx bx-caret-down" />}
            </NavLink>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <NavLink
              activeClassName="navItemActive"
              to={`${baseUrl}/dashboard/admin-tools/user-management`}
              onClick={() => props.handlers.handleShowMobileNav()}
            >
              <Dropdown.Item as="span">
                <i className="bx bx-right-arrow-alt" />
                User Management
              </Dropdown.Item>
            </NavLink>
            <NavLink
              activeClassName="navItemActive"
              to={`${baseUrl}/dashboard/admin-tools/admin-report`}
              onClick={() => props.handlers.handleShowMobileNav()}
            >
              <Dropdown.Item as="span">
                <i className="bx bx-right-arrow-alt" />
                Report
              </Dropdown.Item>
            </NavLink>
            <NavLink
              activeClassName="navItemActive"
              to={`${baseUrl}/dashboard/admin-tools/topup-history`}
              onClick={() => props.handlers.handleShowMobileNav()}
            >
              <Dropdown.Item as="span">
                <i className="bx bx-right-arrow-alt" />
                Top up History
              </Dropdown.Item>
            </NavLink>
            <NavLink
              activeClassName="navItemActive"
              to={`${baseUrl}/dashboard/admin-tools/admin-activity-log`}
              onClick={() => props.handlers.handleShowMobileNav()}
            >
              <Dropdown.Item as="span">
                <i className="bx bx-right-arrow-alt" />
                Admin Activity Log
              </Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  </div>
);

export default NavbarMenu;
