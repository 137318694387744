import React from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from '../shared/Loading/Loading';

const SendMessageTable = ({
  data,
  handleUpdateScheduleStatus,
  handlePageChange,
}) => (
  <div className="row">
    <div className="col-12">
      <Form.Group controlId="dateRange1">
        <Form.Label>Scheduler List</Form.Label>
      </Form.Group>
      <Table responsive>
        <thead>
          <tr>
            <th>#ID</th>
            <th>Schedule Name</th>
            <th>Created Date</th>
            <th>Send Date</th>
            <th>Success</th>
            <th>Failed</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.scheduleListLoading && (
            <tr>
              <td colSpan="8" className="loading-white-bg">
                <Loading />
              </td>
            </tr>
          )}
          {!data.scheduleListLoading &&
            data.scheduleList.length > 0 &&
            data.scheduleList.map((schedule) => (
              <tr key={schedule.id}>
                <td>{schedule.id}</td>
                <td>{schedule.name}</td>
                <td>
                  {moment(schedule.createdAt).format('DD MMMM YYYY, h:mm:ss a')}
                </td>
                <td>
                  {moment(schedule.runAt).format('DD MMMM YYYY, h:mm:ss a')}
                </td>
                <td>{schedule.success.toLocaleString()}</td>
                <td>{schedule.failed.toLocaleString()}</td>
                <td className="badge-wrapper">
                  <span
                    className={
                      'badge badge-pill ' +
                      (schedule.status === 'done'
                        ? 'badge-light-success'
                        : schedule.status === 'on progress'
                        ? 'badge-light-primary'
                        : 'badge-light-danger')
                    }
                  >
                    {schedule.status}
                  </span>
                </td>
                <td className="schedule-management-action">
                  {schedule.status === 'waiting' && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">STOP Schedule</Tooltip>
                      }
                    >
                      <i
                        className="bx bx-block"
                        onClick={() =>
                          handleUpdateScheduleStatus(
                            schedule.id,
                            schedule.status,
                            schedule.runAt
                          )
                        }
                      />
                    </OverlayTrigger>
                  )}
                  {schedule.status === 'stopped' &&
                    moment(schedule.runAt).format() < moment().format() && (
                      <i
                        className="bx bx-play-circle done"
                        onClick={(e) =>
                          handleUpdateScheduleStatus(
                            schedule.id,
                            schedule.status,
                            schedule.runAt,
                            e
                          )
                        }
                      />
                    )}
                  {schedule.status === 'stopped' &&
                    moment(schedule.runAt).format() > moment().format() && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">START Schedule</Tooltip>
                        }
                      >
                        <i
                          className="bx bx-play-circle"
                          onClick={(e) =>
                            handleUpdateScheduleStatus(
                              schedule.id,
                              schedule.status,
                              schedule.runAt,
                              e
                            )
                          }
                        />
                      </OverlayTrigger>
                    )}
                  {(schedule.status === 'on progress' ||
                    schedule.status === 'done') && (
                    <i className="bx bx-block done" />
                  )}
                </td>
              </tr>
            ))}
          {!data.scheduleListLoading && data.scheduleList.length === 0 && (
            <tr className="text-center">
              <td colSpan="8">
                <img
                  alt="no-data-found"
                  src={require('../../assets/shared/search.svg').default}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {data.totalData > 10 && (
        <div className="pagination-custom-style">
          <Pagination
            activePage={data.currentPage}
            itemsCountPerPage={data.perPage}
            totalItemsCount={data.totalData}
            onChange={(e) => handlePageChange(e)}
            hideFirstLastPages
            prevPageText="<PREV"
            nextPageText="NEXT>"
            activeClass="active-page"
            activeLinkClass="active-link"
            itemClass=""
            linkClass="defaul-link"
          />
        </div>
      )}
    </div>
  </div>
);

export default SendMessageTable;
