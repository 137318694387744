import React from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const AddCompanyModal = (props) => (
  <WrapperModal
    show={props.data.showBlacklistModal}
    onClose={props.handlers.handleShowBlacklistModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Blacklist Customer MSISDN',
            handleCloseModal: props.handlers.handleShowBlacklistModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Add MSISDN',
            handleCloseModal: props.handlers.handleShowBlacklistModal,
            handleActionButton: props.handlers.handleBlacklist,
            isLoading: props.data.blacklistLoading,
          }}
        />
      ),
    }}
    centered={true}
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleBlacklist}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Select
                  isSearchable={true}
                  name="companyList"
                  options={props.data.companyList}
                  placeholder="Select Company"
                  onChange={(e) => props.handlers.handleSelectCompany(e)}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: props.data.companyNameError
                        ? '1px solid #dc3545'
                        : '1px solid #ced4da',
                    }),
                  }}
                />
                {props.data.companyNameError && (
                  <Form.Text>Company name is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="customerMSISDN">
                <Form.Label>Customer MSISDN</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="60XXXXXXXXX format"
                  className={props.data.customerMSISDNError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.customerMSISDNError && (
                  <Form.Text>MSISDN is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group>
                <Form.Label>Operation Type</Form.Label>
                <div className="d-flex">
                  <Form.Check
                    id="operationType1"
                    type="radio"
                    label="Blacklist"
                    checked={props.data.operationType}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                  <Form.Check
                    id="operationType2"
                    type="radio"
                    label="Whitelist"
                    checked={!props.data.operationType}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default AddCompanyModal;
